import React, { useState } from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import createMuiTheme from "@material-ui/core/styles/createMuiStrictModeTheme";
import { Redirect, Route, Switch } from "react-router-dom";
import {
    ClientInformationQuery,
    useCreditorConfigurationQuery,
    useMeQuery,
} from "./operations.generated";
import PageLoader from "./components/PageLoader";
import useUrlHelper from "./hooks/useUrlHelper";
import TopNavigation from "./components/TopNavigation";
import LoginView from "./views/login/LoginView";
import OverviewView from "./views/overview/OverviewView";
import CalendarView from "./views/lodging/calendar/CalendarView";
import ReportView from "./views/lodging/report/ReportView";
import { ClientConfigurationContext } from "./ClientConfigurationContext";
import { DateFnsLocaleProvider } from "./DateFnsContext";
import {
    enGB as DateFnsEnLocale,
    da as DateFnsDaLocale,
    sv as DateFnsSvLocale,
} from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import daLocale from "date-fns/locale/da";
import { useApolloClient } from "@apollo/client";
import { Helmet } from "react-helmet";
import { CreditorConfigurationContext } from "./CreditorConfigurationContext";
import MessagesView from "views/messages/MessagesView";
import RentalContractView from "./views/lodging/rentalContract/RentalContractView";

function App({
    clientConfiguration,
}: {
    clientConfiguration: ClientInformationQuery["clientConfiguration"];
}) {
    const [darkTheme] = useState(false);
    const theme = React.useMemo(() => {
        return createMuiTheme({
            palette: {
                type: darkTheme ? "dark" : "light",
                primary: { main: clientConfiguration?.primaryColor || "#3f51b5" },
                secondary: { main: clientConfiguration?.secondaryColor || "#f50057" },
            },
            appBar: {
                color: clientConfiguration.headerTextColor || "#fff",
                backgroundColor: clientConfiguration.headerBackgroundColor || "#3f51b5",
            },
        });
    }, [clientConfiguration, darkTheme]);

    let dateFnsLocale: Locale = DateFnsEnLocale;

    switch (clientConfiguration.defaultCulture) {
        case "da-DK":
            dateFnsLocale = DateFnsDaLocale;
            break;
        case "sv-SE":
            dateFnsLocale = DateFnsSvLocale;
            break;
    }

    return (
        <DateFnsLocaleProvider value={dateFnsLocale}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
                <ThemeProvider theme={theme}>
                    <ClientConfigurationContext value={clientConfiguration}>
                        <Helmet>
                            <title>{clientConfiguration.appTitle}</title>
                        </Helmet>
                        <CssBaseline />
                        <Switch>
                            <Route component={LoginView} path="/login" exact />
                            <Route component={RootView} path="/" exact />
                            <Route component={LoggedInView} />
                        </Switch>
                    </ClientConfigurationContext>
                </ThemeProvider>
            </MuiPickersUtilsProvider>
        </DateFnsLocaleProvider>
    );
}

function RootView() {
    const urlHelper = useUrlHelper();
    const meQuery = useMeQuery();
    return (
        <>
            {meQuery.loading && <PageLoader />}
            {!meQuery.loading && meQuery.data?.me && <Redirect to={urlHelper.overview} />}
            {!meQuery.loading && meQuery.data?.me == null && <Redirect to={urlHelper.login} />}
        </>
    );
}

function LoggedInView() {
    let query = useCreditorConfigurationQuery();

    if (query.loading) {
        return <PageLoader />;
    }

    if (query.error) {
        return <div>Error</div>;
    }

    return (
        <CreditorConfigurationContext value={query.data!.clientConfigurationCreditorSpecific}>
            <TopNavigation />
            <div style={{ paddingTop: "64px" }}></div>
            <Switch>
                <Route component={OverviewView} path="/overview" exact />
                <Route component={MessagesView} path="/messages" exact />
                <Route path="/lodging/:lodging/">
                    <Route component={ReportView} path="/lodging/:lodging/report/:year" exact />
                    <Route component={CalendarView} path="/lodging/:lodging/calendar/:year" exact />
                    <Route
                        component={RentalContractView}
                        path="/lodging/:lodging/rental-contract/:year"
                        exact
                    />
                </Route>
            </Switch>
        </CreditorConfigurationContext>
    );
}

export default App;
