import React, { useEffect, useRef, useState } from "react";
import { Box, ListSubheader, Paper, useTheme } from "@material-ui/core";
import { Bar, BarChart, Tooltip, XAxis } from "recharts";
import { CreditorRentalSummaryReportQuery } from "operations.generated";
import { useTranslation } from "react-i18next";
interface Props {
    data: CreditorRentalSummaryReportQuery;
}

export default function RevenueChart({ data }: Props) {
    const [width, setWidth] = useState<number | null>(0);
    const containerRef = useRef<HTMLDivElement>();
    const { t } = useTranslation();

    useEffect(() => {
        if (containerRef.current) {
            setWidth(containerRef.current?.offsetWidth);
        }
    }, [containerRef]);

    let theme = useTheme();
    return (
        <Paper>
            <ListSubheader>{t("chartTitle")}</ListSubheader>
            <Box p={2} color="text">
                <div ref={containerRef as any}>
                    {width && (
                        <BarChart
                            width={width}
                            height={100}
                            data={data.creditorRentalSummaryReport.chartData}
                            margin={{
                                top: 0,
                                right: 0,
                                left: 0,
                                bottom: 0,
                            }}
                        >
                            <XAxis dataKey="month" />
                            <Tooltip />
                            <Bar dataKey="amount" fill={theme.palette.secondary.light} />
                        </BarChart>
                    )}
                </div>
            </Box>
        </Paper>
    );
}
