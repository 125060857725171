import React from "react";
import { CreditorRentalSummaryReportQuery } from "operations.generated";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
    Theme,
    Typography,
    useTheme,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import FancyPeriod from "components/FancyPeriod";
import { useDetailReservationDrawer } from "../../../drawers/DetailReservationView";
import { useTranslation } from "react-i18next";

export function ReservationList({ data }: { data: CreditorRentalSummaryReportQuery }) {
    const { t } = useTranslation();

    const theme = useTheme();

    const detailReservationDrawer = useDetailReservationDrawer();

    return (
        <div>
            {detailReservationDrawer.drawer}
            <Paper>
                <List subheader={<ListSubheader>{t("reservations")}</ListSubheader>}>
                    {data.creditorRentalSummaryReport?.reservations.map((reservation, index) => (
                        <React.Fragment key={index}>
                            {index !== 0 && <Divider />}
                            <ReservationListItem
                                onClick={() => {
                                    detailReservationDrawer.setDetailReservationAndOpen(
                                        reservation
                                    );
                                }}
                                theme={theme}
                                reservation={reservation}
                            />
                        </React.Fragment>
                    ))}
                    {data.creditorRentalSummaryReport.reservations.length === 0 && (
                        <ListItem>
                            <ListItemText>{t("noReservations")}</ListItemText>
                        </ListItem>
                    )}
                </List>
            </Paper>
        </div>
    );
}

interface ReservationListItemProps {
    reservation: CreditorRentalSummaryReportQuery["creditorRentalSummaryReport"]["reservations"][0];
    onClick: (id: number) => void;
    theme: Theme;
}

function ReservationListItem({ reservation, onClick, theme }: ReservationListItemProps) {
    const { t } = useTranslation();

    const rentalPaid = reservation.paidToCreditor >= reservation.bookingSum;
    const consumptionPaid = reservation.balance === 0 && reservation.consumptionSum > 0;
    const theme1 = useTheme();
    return (
        <ListItem
            button
            onClick={() => {
                onClick(reservation.reservationId);
            }}
            style={{ minHeight: "72px" }}
        >
            <ListItemText>
                <Box
                    display="grid"
                    gridTemplateColumns="min-content 1fr"
                    justifyContent="space-between"
                    alignItems="center"
                    gridGap={theme1.spacing(1)}
                >
                    <FancyPeriod
                        arrivalDate={reservation.arrivalDate}
                        departureDate={reservation.departureDate}
                    />

                    <div
                        style={{
                            display: "grid",
                            gridTemplateColumns: "min-content 1fr",
                            gap: `0 ${theme.spacing(1)}px`,
                            justifySelf: "end",
                        }}
                    >
                        <Box alignSelf="center">
                            <Typography variant="body2" color="textSecondary">
                                {t("rental")}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Typography style={{ color: rentalPaid ? green[500] : undefined }}>
                                {reservation.bookingSumDisplayValue}{" "}
                            </Typography>
                        </Box>
                        <Box alignSelf="center">
                            <Typography variant="body2" color="textSecondary">
                                {t("consumption")}
                            </Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <Typography style={{ color: consumptionPaid ? green[500] : undefined }}>
                                {reservation.consumptionSumDisplayValue}{" "}
                            </Typography>
                        </Box>
                    </div>
                </Box>
            </ListItemText>
        </ListItem>
    );
}

// Old overview on row:

/*
{false && r.balance !== 0 && (
                                        <div>
                                            <TableContainer>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <div>
                                                                    <strong>
                                                                        {translations.ownerPrice}
                                                                    </strong>
                                                                </div>
                                                                <div>- {translations.rental}</div>
                                                                <div>
                                                                    - {translations.consumption}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <div>
                                                                    <strong>
                                                                        {r.principalSumDisplayValue ??
                                                                            "0"}
                                                                    </strong>
                                                                </div>
                                                                <div>
                                                                    {r.bookingSumDisplayValue ?? ""}
                                                                </div>
                                                                <div>
                                                                    {r.consumptionSumDisplayValue ??
                                                                        "0"}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <strong>{translations.paid}</strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>
                                                                    {r.paidToCreditorDisplayValue ??
                                                                        "0"}
                                                                </strong>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell component="th" scope="row">
                                                                <strong>
                                                                    {translations.balance}
                                                                </strong>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <strong>
                                                                    {r.balanceDisplayValue ?? "0"}
                                                                </strong>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    )}
 */
