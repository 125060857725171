import React from "react";

import { Box, Card, CardMedia, CircularProgress, Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { useRouteMatch } from "react-router";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import YearPicker from "components/YearPicker";
import RevenueChart from "./components/RevenueChart";
import useSelectedLodgingId from "hooks/useSelectedLodgingId";
import BlockingList from "./components/BlockingList";
import { useCreditorRentalSummaryReportQuery } from "operations.generated";
import { ReservationList } from "./components/ReservationList";
import ExpensesList from "./components/ExpensesList";
import useSelectedYear from "hooks/useSelectedYear";
import BottomPanel from "components/BottomPanel";
import { Summary } from "./components/Summary";

const useStyles = makeStyles({
    media: {
        height: "240px",
    },
});

export default function ReportView() {
    const match = useRouteMatch<{ lodging: string }>();
    const lodgingId = useSelectedLodgingId();
    const year = useSelectedYear();

    const { data, loading, error } = useCreditorRentalSummaryReportQuery({
        variables: {
            lodgingId: parseInt(match.params.lodging),
            year,
        },
    });
    const classes = useStyles();

    if (error) {
        if (error.graphQLErrors[0].extensions.data["DisplayToClient"]) {
            return <div style={{ marginTop: "20px", marginLeft: "20px"}}>{error.graphQLErrors[0].message}</div>;
        }
        return <div style={{ marginTop: "20px", marginLeft: "20px"}}>Error</div>;
    }

    if (loading || data == null) {
        return (
            <Container>
                <YearPicker />
                <Box
                    style={{
                        display: "flex",
                        marginTop: "150px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
                <BottomPanel />
            </Container>
        );
    }

    const lodging = data.me?.lodgings.find((l) => l.id === lodgingId)!;

    return (
        <Container>
            <YearPicker />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardMedia
                            className={classes.media}
                            image={lodging.primaryImage?.locationTemplate.replace(
                                "{size}",
                                "auto-480"
                            )}
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {lodging.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {lodging.address}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6} style={{ display: "grid", alignItems: "stretch" }}>
                    <Summary data={data} />
                </Grid>
            </Grid>

            <Box pb={2} pt={2}>
                <RevenueChart data={data} />
            </Box>

            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <ReservationList data={data} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box mb={2}>
                        <BlockingList data={data} />
                    </Box>
                    <Box mb={2}>
                        <ExpensesList data={data} />
                    </Box>
                    {/*<Box>*/}
                    {/*    <RevenueChart />*/}
                    {/*</Box>*/}
                </Grid>
            </Grid>
            <BottomPanel />
        </Container>
    );
}
