import { CreditorRentalSummaryReportQuery } from "operations.generated";
import TableContainer from "@material-ui/core/TableContainer";
import { Paper, TableCell } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import { useTranslation } from "react-i18next";

export function Summary({ data }: { data: CreditorRentalSummaryReportQuery }) {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <div>
                                <strong>{t("ownerPrice")}</strong>
                            </div>
                            <div>- {t("rental")}</div>
                            <div>- {t("consumption")}</div>
                        </TableCell>
                        <TableCell align="right">
                            <div>
                                <strong>
                                    {data?.creditorRentalSummaryReport?.principalSumDisplayValue ??
                                        "0"}
                                </strong>
                            </div>
                            <div>
                                {data?.creditorRentalSummaryReport?.bookingSumDisplayValue ?? ""}
                            </div>
                            <div>
                                {data?.creditorRentalSummaryReport?.consumptionSumDisplayValue ??
                                    "0"}
                            </div>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <strong>{t("regulations")}</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong>
                                {data?.creditorRentalSummaryReport?.expensesSumDisplayValue ?? "0"}
                            </strong>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <strong>{t("paid")}</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong>
                                {data?.creditorRentalSummaryReport?.paidToCreditorSumDisplayValue ??
                                    "0"}
                            </strong>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <strong>{t("balance")}</strong>
                        </TableCell>
                        <TableCell align="right">
                            <strong>
                                {data?.creditorRentalSummaryReport?.balanceDisplayValue ?? "0"}
                            </strong>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
