import React, { useEffect, useState } from "react";
import {
    MeDocument,
    useMeQuery,
    useSendMessageFromCreditorMutation,
} from "../../operations.generated";
import PageLoader from "../../components/PageLoader";
import MessageList from "../../components/MessageList";
import { Button, Card, Container, Form } from "react-bootstrap";
import { getOperationName } from "@apollo/client/utilities";
import { useTranslation } from "react-i18next";

export default function MessagesView() {
    const { loading, data, error } = useMeQuery();
    const [message, setMessage] = useState("");
    const [sendMessageFromCreditor, mutation] = useSendMessageFromCreditorMutation();

    const { t } = useTranslation();

    useEffect(() => {
        window.scroll({
            top: window.document.body.scrollHeight,
            behavior: "auto",
        });
    }, [data]);

    if (loading) {
        return <PageLoader />;
    }

    if (error) {
        if (error.graphQLErrors[0].extensions.data["DisplayToClient"]) {
            return <div style={{ marginTop: "20px", marginLeft: "20px"}}>{error.graphQLErrors[0].message}</div>;
        }
        return <div style={{ marginTop: "20px", marginLeft: "20px"}}>Error</div>;
    }
    async function sendMessage() {
        await sendMessageFromCreditor({
            variables: {
                message,
            },
            refetchQueries: [getOperationName(MeDocument)],
            awaitRefetchQueries: true,
        });
        setMessage("");
    }

    return (
        <Container className="mt-3 mb-3">
            {data.me.messages.length > 0 && (
                <Card>
                    <Card.Body>
                        <MessageList messages={data.me.messages} />
                    </Card.Body>
                </Card>
            )}
            <Card className="mt-2">
                <Card.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("writeMessage")}</Form.Label>
                        <Form.Control
                            as="textarea"
                            onChange={(e) => setMessage(e.currentTarget.value)}
                            value={message}
                        />
                    </Form.Group>
                    <Button
                        variant="primary"
                        disabled={message.length == 0 || mutation.loading}
                        onClick={() => sendMessage()}
                    >
                        Send
                    </Button>
                </Card.Body>
            </Card>
        </Container>
    );
}
