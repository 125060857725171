import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
    NormalizedCacheObject
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import AppWrapper from "./AppWrapper";
import useUrlHelper from "./hooks/useUrlHelper";
import PageLoader from "./components/PageLoader";
import "bootstrap/dist/css/bootstrap.min.css";
import "./i18n";
import { useLogoutMutation } from "./operations.generated";

let client: ApolloClient<NormalizedCacheObject> | null = null;
console.log("loaded");
const pathTokens = window.location.pathname.split("/");
const tenantId = pathTokens[1];
const segmentation = pathTokens[2];

// We add the JWT token as a header: https://www.apollographql.com/docs/react/networking/authentication/#header
const httpLink = createHttpLink({
    uri: `/${tenantId}/${segmentation}/graphql`
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    // eslint-disable-next-line eqeqeq

    console.log(graphQLErrors);
    if (graphQLErrors && graphQLErrors.some((e) => e.extensions?.number == "authorization")) {
        console.error("Trying to access resource that requires login. Redirecting to login");

        window.location.href = `/${tenantId}/${segmentation}/login`;
    }
});
//
// const authLink = setContext((_, { headers }) => {
//     // get the authentication token from local storage if it exists
//     const token = localStorage.getItem("token");
//     // return the headers to the context so httpLink can read them
//     return {
//         headers: {
//             ...headers,
//             authorization: token ? `Bearer ${token}` : "",
//         },
//     };
// });

const cache = new InMemoryCache();

export function clearApolloCache() {
    return client!.resetStore();
}

export function getClient() {
    if (client == null) {
        client = new ApolloClient({
            // link: authLink.concat(errorLink.concat(httpLink)),
            link: errorLink.concat(httpLink),
            cache: cache
        });
    }
    return client;
}

export function deleteAuthToken() {
    localStorage.removeItem("token");
}

(window as any)["run-react"] = () => {
    if (!tenantId) {
        ReactDOM.render(<div>Tenant ID missing</div>, document.getElementById("root"));
    } else {
        ReactDOM.render(
            <React.StrictMode>
                <ApolloProvider client={getClient()}>
                    <BrowserRouter basename={`/${tenantId}/${segmentation}`}>
                        <Switch>
                            <Route path="/logout" component={LogoutComponent} />
                            <Route component={AppWrapper} />
                        </Switch>
                    </BrowserRouter>
                </ApolloProvider>
            </React.StrictMode>, document.getElementById("react-root"));
        window["__viewLoaded"] = true;
    }
};

// Enables Hot Module Replacement
// Hot Module Replacement is a thing where we really are just replacing the script tags
// When that is done we just ask to load the view again
if ((module as any).hot) {
    (module as any).hot.accept();
    if (window["__viewLoaded"] == true) {
        (window as any)["run-react"]();
    }
}

function LogoutComponent() {
    const history = useHistory();
    const urlHelper = useUrlHelper();
    const [logout] = useLogoutMutation();

    useEffect(() => {
        logout().then(() => {
            clearApolloCache().then(() => {
                history.push(urlHelper.login);
            });
        });
        // eslint-disable-next-line
    }, []);

    return <PageLoader />;
}
