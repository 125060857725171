import React from "react";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    label: {
        paddingLeft: "4px",
    },
});

export default function GreenCheckItem({ label }: { label: string }) {
    const classes = useStyles();

    return (
        <Box color={green[500]} display="flex" alignItems="center">
            <CheckCircleIcon />
            <span className={classes.label}>{label}</span>
        </Box>
    );
}
