import { useMemo } from "react";

export default function useUrlHelper() {
    return useMemo(() => {
        return {
            overview: `/overview/`,
            login: "/login",
            logout: "/logout/",
            messages: "/messages/",
            lodgingReport: (lodgingId: number, year: number | null = null) =>
                `/lodging/${lodgingId}/report/${year ?? new Date().getFullYear()}`,
            lodgingCalendar: (lodgingId: number, year: number | null = null) =>
                `/lodging/${lodgingId}/calendar/${year ?? new Date().getFullYear()}`,
            lodgingRentalContract: (lodgingId: number, year: number | null = null) =>
                `/lodging/${lodgingId}/rental-contract/${year ?? new Date().getFullYear()}`,
        };
    }, []);
}
