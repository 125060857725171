import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    addDays,
    getDate,
    getDaysInMonth,
    getISODay,
    getISOWeek,
    isAfter,
    isBefore,
    isSameDay,
} from "date-fns";
import { Box, Paper, Typography } from "@material-ui/core";
import {
    CreditorRentalSummaryReportBlockingFragment,
    CreditorRentalSummaryReportReservationFragment,
} from "../operations.generated";
import { useDateFnsLocale } from "../DateFnsContext";
import { useCreditorConfiguration } from "../CreditorConfigurationContext";
import { useTranslation } from "react-i18next";

type DateItemClickedHandler = (dateItem: DateItem) => void;

interface Props {
    month: number;
    year: number;
    dateItems: DateItem[];
    onDateItemClicked: DateItemClickedHandler;
}

export type DateItem = ReservationDateItem | OptionDateItem | BlockingDateItem | ShadowDateItem;

export interface ReservationDateItem {
    from: Date;
    to: Date;
    type: "reservation";
    reservation: CreditorRentalSummaryReportReservationFragment;
    shadowReservationId?: number;
}

export interface ShadowDateItem {
    from: Date;
    to: Date;
    type: "shadow";
    shadowReservationId?: number;
}

export interface OptionDateItem {
    from: Date;
    to: Date;
    type: "option";
    reservation: CreditorRentalSummaryReportReservationFragment;
    shadowReservationId?: number;
}

export interface OptionDateItem {
    from: Date;
    to: Date;
    type: "option";
    reservation: CreditorRentalSummaryReportReservationFragment;
}

export interface BlockingDateItem {
    from: Date;
    to: Date;
    type: "blocking";
    blocking: CreditorRentalSummaryReportBlockingFragment;
    color?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "280px",
    },
    table: {
        ...theme.typography.body2,
        height: "170px",
        width: "100%",
    },
    tr: { ...theme.typography.body2 },
    td: { ...theme.typography.body2, textAlign: "center", width: "32px", height: "32px" },
    th: {
        ...theme.typography.body2,
        textAlign: "center",
        color: theme.palette.text.hint,
        width: "32px",
        height: "32px",
    },
}));

export default function MonthCalendar({ month, year, dateItems, onDateItemClicked }: Props) {
    const classes = useStyles();

    const calendarRows = useCalendarDateTable({ month, year });
    const dateFnsLocale = useDateFnsLocale();

    return (
        <div>
            <Paper elevation={1} className={classes.root}>
                <Box p={1}>
                    <Box textAlign="center">
                        <Typography variant={"body1"}>
                            {dateFnsLocale.localize?.month(month - 1)}
                        </Typography>
                    </Box>
                    <table className={classes.table}>
                        <thead>
                            <tr className={classes.tr}>
                                <th />
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(1).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(2).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(3).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(4).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(5).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(6).slice(0, 2)}
                                </th>
                                <th className={classes.th}>
                                    {dateFnsLocale.localize?.day(0).slice(0, 2)}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {calendarRows.map((row, index) => (
                                <tr key={index} className={classes.tr}>
                                    <th className={classes.th}>
                                        {getISOWeek(row.find((r) => r !== undefined) as Date)}
                                    </th>
                                    {row.map((d, index) => (
                                        <DayCell
                                            key={d?.getTime() ?? index}
                                            day={d}
                                            dateItems={dateItems}
                                            onDateItemClicked={onDateItemClicked}
                                        />
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Paper>
        </div>
    );
}

function getCellStyle(dateItem: DateItem) {
    if (dateItem.type === "reservation") {
        return ["#fff", "blue"];
    }
    if (dateItem.type === "option") {
        return ["#fff", "lightblue"];
    }
    if (dateItem.type === "shadow") {
        return ["#fff", "lightgray"];
    }
    if (dateItem.type === "blocking" && dateItem.color != undefined && dateItem.color != "") {
        return [calcHexColorLuminosity(dateItem.color) < 128 ? "#fff" : "black", dateItem.color];
    } else if (dateItem.type === "blocking") {
        return ["#fff", "red"];
    }

    return [undefined, undefined];
}

function calcHexColorLuminosity(hexColor: string) {
    let r = parseInt("0x" + hexColor.substr(1, 2));
    let g = parseInt("0x" + hexColor.substr(3, 2));
    let b = parseInt("0x" + hexColor.substr(5, 2));
    return r * 0.2126 + g * 0.7152 + b * 0.0722;
}

function DayCell({
    day,
    dateItems,
    onDateItemClicked,
}: {
    day: Date | undefined;
    dateItems: DateItem[];
    onDateItemClicked: DateItemClickedHandler;
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const creditorConfiguration = useCreditorConfiguration();

    const matchingDateItems = useMemo(() => {
        if (day === undefined) {
            return [];
        }
        return dateItems
            .filter(
                (di) =>
                    (isAfter(day, di.from) || isSameDay(day, di.from)) &&
                    (isBefore(day, di.to) || isSameDay(day, di.to))
            )
            .sort((a, b) => (a.from.getTime() > b.from.getTime() ? 1 : -1));
    }, [dateItems, day]);

    let color = undefined;
    let backgroundColor = undefined;
    let nextColor = undefined;
    let nextBackgroundColor = undefined;

    if (matchingDateItems.length > 0) {
        let matchingDateItem = matchingDateItems[0];
        [color, backgroundColor] = getCellStyle(matchingDateItem);
    }
    if (matchingDateItems.length > 1) {
        let matchingDateItem = matchingDateItems[1];
        [nextColor, nextBackgroundColor] = getCellStyle(matchingDateItem);
    }

    const title = useMemo(() => {
        if (matchingDateItems.length > 0) {
            let matchingDateItem = matchingDateItems[0];
            switch (matchingDateItem.type) {
                case "reservation":
                    return t("reservation") + " " + (matchingDateItem.reservation?.reservationId || matchingDateItem.shadowReservationId);
                case "option":
                    return t("option") + " " + (matchingDateItem.reservation?.reservationId || matchingDateItem.shadowReservationId);
                case "blocking":
                    return t("blocking") + " " + matchingDateItem.blocking.blockingGroup;
                case "shadow":
                    return t("reservationFromDifferentYear", {year: matchingDateItem.from.getFullYear()}) + " (" + (matchingDateItem.shadowReservationId)+")";
            }
        }
    }, [matchingDateItems, t]);

    function handleOnClick() {
        if (matchingDateItems.length > 0) {
            onDateItemClicked(matchingDateItems[0]);
        }
    }

    let cursorStyle = undefined;

    if (matchingDateItems.length > 0) {
        if (matchingDateItems[0].type === "reservation" || matchingDateItems[0].type === "option") {
            cursorStyle = "pointer";
        } else if (
            matchingDateItems[0].type === "blocking"
        ) {
            cursorStyle = "pointer";
        }
    }

    let isStart =
        day !== undefined &&
        matchingDateItems.length > 0 &&
        isSameDay(day, matchingDateItems[0].from);
    let isEnd =
        day !== undefined &&
        matchingDateItems.length > 0 &&
        isSameDay(day, matchingDateItems[0].to);
    let hasMultipleLayers = matchingDateItems.length > 1 || isStart || isEnd;

    let bottomLayer: CellStyle = { color, backgroundColor };
    let nextLayer: CellStyle = { color: nextColor, backgroundColor: nextBackgroundColor };

    // We only have one event - but it starts - so we want to only fill the "next"-half
    if (matchingDateItems.length === 1 && isStart) {
        bottomLayer = { color: "inherit", backgroundColor: "inherit" };
        nextLayer = { color, backgroundColor };
    }

    // We only have one event - but it starts - so we want to only fill the "prev"-half
    if (matchingDateItems.length === 1 && isEnd) {
        nextLayer = { color: "rgba(0, 0, 0, 0.87)", backgroundColor: "#fff" };
    }

    return (
        <td
            className={classes.td}
            style={{
                cursor: cursorStyle,
                color: bottomLayer.color,
                backgroundColor: bottomLayer.backgroundColor,
                position: "relative",
            }}
            title={title}
            onClick={handleOnClick}
        >
            {hasMultipleLayers && (
                <>
                    {bottomLayer.backgroundColor === nextLayer.backgroundColor && (
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                bottom: 0,
                                left: 0,
                                right: 0,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                clipPath: "polygon(100% 0, 0% 100%, 100% 100%)",
                            }}
                        >
                            {day !== undefined ? getDate(day) : null}
                        </div>
                    )}
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: nextLayer.color,
                            backgroundColor: nextLayer.backgroundColor,
                            clipPath: "polygon(103% 0, 0% 103%, 103% 103%)",
                        }}
                    >
                        {day !== undefined ? getDate(day) : null}
                    </div>
                </>
            )}
            {day !== undefined ? getDate(day) : null}
        </td>
    );
}

function useCalendarDateTable({ year, month }: { year: number; month: number }) {
    return useMemo(() => {
        const startDate = new Date(year, month - 1, 1);
        const numberOfDays = getDaysInMonth(startDate);

        let days = [];
        for (let x = 1; x <= numberOfDays; x++) {
            days.push(addDays(startDate, x - 1));
        }

        return days.reduce<Array<Array<Date | undefined>>>((prev, cur) => {
            if (prev.length === 0) {
                // The first row will have empty cells at the beginning.
                // We will add them and the first date in the first run
                let numberOfDuds = getISODay(cur) - 1;
                let duds = [];
                for (let x = 1; x <= numberOfDuds; x++) {
                    duds.push(undefined);
                }
                return [[...duds, cur]];
            } else {
                // If it is the first day of a week - start a new row
                if (getISODay(cur) === 1) {
                    return [...prev, [cur]];
                } else {
                    // We need to append our value to the last row and clone the rest
                    // There is properly a nicer way to do this. Maybe mutation is okay in a reducer?
                    const [tail, ...head] = [...prev.reverse()];
                    return [...head.reverse(), [...tail, cur]];
                }
            }
        }, []);
    }, [year, month]);
}

interface CellStyle {
    color: string | undefined;
    backgroundColor: string | undefined;
}
