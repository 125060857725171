import { Box, CircularProgress } from "@material-ui/core";
import React from "react";

export default function PageLoader() {
    return (
        <Box
            style={{
                display: "flex",
                height: "100vh",
                width: "100vw",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
}
