import { Box, Paper } from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import React from "react";
import useSelectedLodgingId from "../hooks/useSelectedLodgingId";
import useUrlHelper from "../hooks/useUrlHelper";
import useSelectedYear from "../hooks/useSelectedYear";
import { useTranslation } from "react-i18next";
import { useLodgingPages } from "../navigation";

export default function BottomPanel() {
    const lodgingId = useSelectedLodgingId();
    const urlHelper = useUrlHelper();
    const { t } = useTranslation();
    const selectedYear = useSelectedYear();
    const lodgingPage = useLodgingPages();

    let activeTab = lodgingPage.findIndex((l) => l.isActive);

    return (
        <>
            <div style={{ height: "66px" }}></div>
            <Box style={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 4 }}>
                <Paper elevation={4}>
                    <BottomNavigation
                        value={activeTab}
                        onChange={(event, newValue) => {
                            lodgingPage[newValue].goToPage(lodgingId);
                        }}
                        showLabels
                    >
                        {lodgingPage.map((lodgingPage) => (
                            <BottomNavigationAction
                                label={lodgingPage.name}
                                icon={lodgingPage.icon}
                            />
                        ))}
                    </BottomNavigation>
                </Paper>
            </Box>
        </>
    );
}
