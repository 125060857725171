import { MessageFragment } from "../operations.generated";
import Message from "./Message";
import { useMemo } from "react";
export default function MessageList({ messages }: { messages: MessageFragment[] }) {
    const flippedMessages = useMemo(() => {
        return [...messages].reverse();
    }, [messages]);
    
    return (
        <>
            {flippedMessages.map((message, index) => (
                <>
                    {(index == 0 ||
                        flippedMessages[index - 1].createdAtDisplayValue !=
                            message.createdAtDisplayValue) && (
                        <div className="text-center text-black-50 pb-1">
                            {message.createdAtDisplayValue}
                        </div>
                    )}

                    <Message key={message.id} message={message} />
                </>
            ))}
        </>
    );
}
