export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    DateTime: any;
    Decimal: any;
};

export type BlockedAllotmentHistoryItem = {
    __typename?: "BlockedAllotmentHistoryItem";
    actionDate: Scalars["DateTime"];
    actionName: Scalars["String"];
    actionUserId: Scalars["Int"];
    blockedAllotmentId: Scalars["Int"];
    blockedAllotmentTypeId: Scalars["Int"];
    cleaningOrderedOnDeparture: Scalars["Boolean"];
    fromDate: Scalars["DateTime"];
    howMany: Scalars["Int"];
    id: Scalars["Int"];
    isTotalBlocking: Scalars["Boolean"];
    lodgingId: Scalars["Int"];
    lodgingUnitTypeId: Scalars["Int"];
    note?: Maybe<Scalars["String"]>;
    publicNote?: Maybe<Scalars["String"]>;
    toDate: Scalars["DateTime"];
};

export type Booking = {
    __typename?: "Booking";
    arrivalDate: Scalars["DateTime"];
    departureDate: Scalars["DateTime"];
    lodgingId: Scalars["Int"];
    lodgingUnitTypeId: Scalars["Int"];
    reservationId: Scalars["Int"];
};

export type ChartData = {
    __typename?: "ChartData";
    amount?: Maybe<Scalars["Decimal"]>;
    month: Scalars["String"];
};

export type ClientConfiguration = {
    __typename?: "ClientConfiguration";
    appTitle: Scalars["String"];
    blockingGroupColors: Array<KeyValue>;
    blockingGroupTranslations: Array<KeyValue>;
    blockingTypeId: Scalars["Int"];
    defaultCulture: Scalars["String"];
    headerBackgroundColor?: Maybe<Scalars["String"]>;
    headerTextColor?: Maybe<Scalars["String"]>;
    logoUrl?: Maybe<Scalars["String"]>;
    primaryColor?: Maybe<Scalars["String"]>;
    secondaryColor?: Maybe<Scalars["String"]>;
    showMessages: Scalars["Boolean"];
    showRentalContracts: Scalars["Boolean"];
};

export type ClientConfigurationCreditorSpecific = {
    __typename?: "ClientConfigurationCreditorSpecific";
    allowCreateBlocking: Scalars["Boolean"];
    allowDeleteBlocking: Scalars["Boolean"];
};

export type CreateBlockingInput = {
    comment?: InputMaybe<Scalars["String"]>;
    from: Scalars["DateTime"];
    lodgingId: Scalars["Int"];
    to: Scalars["DateTime"];
};

export type Creditor = {
    __typename?: "Creditor";
    id: Scalars["Int"];
    lodging?: Maybe<Lodging>;
    lodgings: Array<Lodging>;
    messages: Array<Message>;
    name: Scalars["String"];
};

export type CreditorLodgingArgs = {
    lodgingId?: InputMaybe<Scalars["Int"]>;
};

export type CreditorRentalSummaryReport = {
    __typename?: "CreditorRentalSummaryReport";
    allotments: Array<CreditorRentalSummaryReportAllotment>;
    alwaysShowManualInvoices: Scalars["Boolean"];
    balance?: Maybe<Scalars["Decimal"]>;
    balanceDisplayValue?: Maybe<Scalars["String"]>;
    blockings: Array<CreditorRentalSummaryReportBlocking>;
    blockingsHistory: Array<BlockedAllotmentHistoryItem>;
    bookingSum?: Maybe<Scalars["Decimal"]>;
    bookingSumDisplayValue?: Maybe<Scalars["String"]>;
    chartData: Array<ChartData>;
    consumptionSum?: Maybe<Scalars["Decimal"]>;
    consumptionSumDisplayValue?: Maybe<Scalars["String"]>;
    creditorId: Scalars["Int"];
    expenses: Array<CreditorRentalSummaryReportExpense>;
    expensesSum?: Maybe<Scalars["Decimal"]>;
    expensesSumDisplayValue?: Maybe<Scalars["String"]>;
    paidToCreditorSum?: Maybe<Scalars["Decimal"]>;
    paidToCreditorSumDisplayValue?: Maybe<Scalars["String"]>;
    payments: Array<CreditorRentalSummaryReportPayment>;
    principalSum?: Maybe<Scalars["Decimal"]>;
    principalSumDisplayValue?: Maybe<Scalars["String"]>;
    reservations: Array<CreditorRentalSummaryReportReservation>;
    showCanceled: Scalars["Boolean"];
    showOnlyBookingInvoiceWith1stRatePaid: Scalars["Boolean"];
    year: Scalars["Int"];
};

export type CreditorRentalSummaryReportBalanceDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportBookingSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportConsumptionSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportExpensesSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportPaidToCreditorSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportPrincipalSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportAllotment = {
    __typename?: "CreditorRentalSummaryReportAllotment";
    allotmentFrom: Scalars["DateTime"];
    allotmentRelease: Scalars["Int"];
    allotmentTo: Scalars["DateTime"];
    allotmentType: Scalars["String"];
    lodgingId: Scalars["Int"];
    lodgingUnitTypeId: Scalars["Int"];
};

export type CreditorRentalSummaryReportBlocking = {
    __typename?: "CreditorRentalSummaryReportBlocking";
    blockedFrom: Scalars["DateTime"];
    blockedFromDisplayValue?: Maybe<Scalars["String"]>;
    blockedTo: Scalars["DateTime"];
    blockedToDisplayValue?: Maybe<Scalars["String"]>;
    blockingGroup: Scalars["String"];
    blockingGroupId?: Maybe<Scalars["Int"]>;
    id: Scalars["Int"];
    lodgingId: Scalars["Int"];
    lodgingUnitTypeId: Scalars["Int"];
    note?: Maybe<Scalars["String"]>;
    publicNote?: Maybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportBlockingBlockedFromDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportBlockingBlockedToDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportExpense = {
    __typename?: "CreditorRentalSummaryReportExpense";
    balance: Scalars["Decimal"];
    balanceDisplayValue?: Maybe<Scalars["String"]>;
    description: Scalars["String"];
    displayCurrency: Scalars["String"];
    fileAttachments: Array<CreditorRentalSummaryReportFileAttachment>;
    isCanceled: Scalars["Boolean"];
    notes: Array<CreditorRentalSummaryReportNote>;
    orderDate: Scalars["DateTime"];
    orderDateDisplayValue?: Maybe<Scalars["String"]>;
    orderItemId: Scalars["Int"];
    paidAmount: Scalars["Decimal"];
    paidAmountDisplayValue?: Maybe<Scalars["String"]>;
    principalAmount: Scalars["Decimal"];
    principalAmountDisplayValue?: Maybe<Scalars["String"]>;
    reservationId: Scalars["Int"];
};

export type CreditorRentalSummaryReportExpenseBalanceDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportExpenseOrderDateDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportExpensePaidAmountDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportExpensePrincipalAmountDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportFileAttachment = {
    __typename?: "CreditorRentalSummaryReportFileAttachment";
    contentType: Scalars["String"];
    fileName: Scalars["String"];
    fileSize: Scalars["Int"];
    orderItemId: Scalars["Int"];
    reservationId: Scalars["Int"];
    url: Scalars["String"];
};

export type CreditorRentalSummaryReportItemDetailView = {
    __typename?: "CreditorRentalSummaryReportItemDetailView";
    itemId: Scalars["Int"];
    orderItemId: Scalars["Int"];
    quantity: Scalars["Decimal"];
};

export type CreditorRentalSummaryReportNote = {
    __typename?: "CreditorRentalSummaryReportNote";
    text: Scalars["String"];
};

export type CreditorRentalSummaryReportOrderItem = {
    __typename?: "CreditorRentalSummaryReportOrderItem";
    displayCurrency: Scalars["String"];
    isBookingInvoice: Scalars["Boolean"];
    isCancelled: Scalars["Boolean"];
    isConsumptionInvoice: Scalars["Boolean"];
    lines: Array<CreditorRentalSummaryReportReservationDetail>;
    orderDate: Scalars["DateTime"];
    orderDateDisplayValue?: Maybe<Scalars["String"]>;
    orderItemId: Scalars["Int"];
    orderItemType: Scalars["String"];
};

export type CreditorRentalSummaryReportOrderItemOrderDateDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportPayment = {
    __typename?: "CreditorRentalSummaryReportPayment";
    balance: Scalars["Decimal"];
    description: Scalars["String"];
    displayCurrency: Scalars["String"];
    fileAttachments: Array<CreditorRentalSummaryReportFileAttachment>;
    isCanceled: Scalars["Boolean"];
    orderDate: Scalars["DateTime"];
    orderItemId: Scalars["Int"];
    paidAmount: Scalars["Decimal"];
    principalAmount: Scalars["Decimal"];
    reservationId: Scalars["Int"];
};

export type CreditorRentalSummaryReportReservation = {
    __typename?: "CreditorRentalSummaryReportReservation";
    adults?: Maybe<Scalars["Int"]>;
    arrivalDate?: Maybe<Scalars["DateTime"]>;
    arrivalDisplayValue: Scalars["String"];
    balance: Scalars["Decimal"];
    balanceDisplayValue?: Maybe<Scalars["String"]>;
    bookingSum: Scalars["Decimal"];
    bookingSumDisplayValue?: Maybe<Scalars["String"]>;
    children?: Maybe<Scalars["Int"]>;
    consumptionSum?: Maybe<Scalars["Decimal"]>;
    consumptionSumDisplayValue?: Maybe<Scalars["String"]>;
    containsRebookedNightsWhereCreditorKeepsTheMoney: Scalars["Boolean"];
    departureDate?: Maybe<Scalars["DateTime"]>;
    departureDisplayValue: Scalars["String"];
    displayCurrency: Scalars["String"];
    hasBookings: Scalars["Boolean"];
    hasCanceledDetails: Scalars["Boolean"];
    hasChangedCreditorDetails: Scalars["Boolean"];
    hasCleaningItems: Scalars["Boolean"];
    hasLiveBookings: Scalars["Boolean"];
    infants?: Maybe<Scalars["Int"]>;
    itemDetails: Array<CreditorRentalSummaryReportItemDetailView>;
    lodgingId: Scalars["Int"];
    nights?: Maybe<Scalars["Int"]>;
    orderItems: Array<CreditorRentalSummaryReportOrderItem>;
    paidToCreditor: Scalars["Decimal"];
    paidToCreditorDisplayValue?: Maybe<Scalars["String"]>;
    pets?: Maybe<Scalars["Int"]>;
    previousCustomerBookings: Scalars["Int"];
    previousCustomerBookingsInLodging: Scalars["Int"];
    principalSum: Scalars["Decimal"];
    principalSumDisplayValue?: Maybe<Scalars["String"]>;
    reservationDetails: Array<CreditorRentalSummaryReportReservationDetail>;
    reservationId: Scalars["Int"];
    reservationStatus: ReservationStatus;
};

export type CreditorRentalSummaryReportReservationArrivalDisplayValueArgs = {
    format: Scalars["String"];
};

export type CreditorRentalSummaryReportReservationBalanceDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationBookingSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationConsumptionSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationDepartureDisplayValueArgs = {
    format: Scalars["String"];
};

export type CreditorRentalSummaryReportReservationPaidToCreditorDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationPrincipalSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationDetail = {
    __typename?: "CreditorRentalSummaryReportReservationDetail";
    arrivalDate?: Maybe<Scalars["DateTime"]>;
    arrivalDisplayValue?: Maybe<Scalars["String"]>;
    country: Scalars["String"];
    customerId: Scalars["Int"];
    customerName: Scalars["String"];
    departureDate?: Maybe<Scalars["DateTime"]>;
    departureDisplayValue?: Maybe<Scalars["String"]>;
    displayCurrency?: Maybe<Scalars["String"]>;
    fileAttachments: Array<CreditorRentalSummaryReportFileAttachment>;
    invoiceLineDescription: Scalars["String"];
    isBookingInvoice: Scalars["Boolean"];
    isCanceled: Scalars["Boolean"];
    isConsumptionInvoice: Scalars["Boolean"];
    isCustomerDeleted: Scalars["Boolean"];
    itemId?: Maybe<Scalars["Int"]>;
    orderDate: Scalars["DateTime"];
    orderDateDisplayValue: Scalars["String"];
    orderItemId: Scalars["Int"];
    orderItemType: Scalars["String"];
    principalSum: Scalars["Decimal"];
    principalSumDisplayValue?: Maybe<Scalars["String"]>;
};

export type CreditorRentalSummaryReportReservationDetailArrivalDisplayValueArgs = {
    format: Scalars["String"];
};

export type CreditorRentalSummaryReportReservationDetailDepartureDisplayValueArgs = {
    format: Scalars["String"];
};

export type CreditorRentalSummaryReportReservationDetailOrderDateDisplayValueArgs = {
    format: Scalars["String"];
};

export type CreditorRentalSummaryReportReservationDetailPrincipalSumDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type Image = {
    __typename?: "Image";
    locationTemplate: Scalars["String"];
};

export type KeyValue = {
    __typename?: "KeyValue";
    key: Scalars["String"];
    value: Scalars["String"];
};

export type Lodging = {
    __typename?: "Lodging";
    address?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    images: Array<Image>;
    name: Scalars["String"];
    primaryImage?: Maybe<Image>;
    rentalContract?: Maybe<RentalContractStatus>;
};

export type LodgingRentalContractArgs = {
    year?: InputMaybe<Scalars["Int"]>;
};

export type Message = {
    __typename?: "Message";
    createdAt: Scalars["DateTime"];
    createdAtDisplayValue?: Maybe<Scalars["String"]>;
    id: Scalars["Int"];
    messageDirection: MessageDirection;
    messageText: Scalars["String"];
};

export type MessageCreatedAtDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export enum MessageDirection {
    FromBureau = "FROM_BUREAU",
    FromCreditor = "FROM_CREDITOR",
}

export type RentalContractComment = {
    __typename?: "RentalContractComment";
    comment: Scalars["String"];
    commentDirection?: Maybe<MessageDirection>;
    senderName: Scalars["String"];
    timeStamp: Scalars["DateTime"];
    timeStampDisplayValue?: Maybe<Scalars["String"]>;
};

export type RentalContractCommentTimeStampDisplayValueArgs = {
    format?: InputMaybe<Scalars["String"]>;
};

export type RentalContractStatus = {
    __typename?: "RentalContractStatus";
    comments?: Maybe<Array<Maybe<RentalContractComment>>>;
    id: Scalars["Int"];
    lodgingUnitTypeId: Scalars["Int"];
    revision: Scalars["Int"];
    status: Scalars["Int"];
    year: Scalars["Int"];
};

export enum ReservationStatus {
    CanceledCreditorDontKeepMoney = "CANCELED_CREDITOR_DONT_KEEP_MONEY",
    CanceledCreditorKeepMoney = "CANCELED_CREDITOR_KEEP_MONEY",
    Invoice = "INVOICE",
    Option = "OPTION",
    Quote = "QUOTE",
    Unknown = "UNKNOWN",
}

export type RootMutation = {
    __typename?: "RootMutation";
    acceptRentalContract?: Maybe<Scalars["Boolean"]>;
    commentRentalContract?: Maybe<Scalars["Boolean"]>;
    createBlocking?: Maybe<Scalars["Boolean"]>;
    deleteBlocking?: Maybe<Scalars["Boolean"]>;
    logout?: Maybe<Scalars["Boolean"]>;
    rejectRentalContract?: Maybe<Scalars["Boolean"]>;
    sendMessageFromCreditor?: Maybe<Scalars["Boolean"]>;
    updateBlocking?: Maybe<Scalars["Boolean"]>;
};

export type RootMutationAcceptRentalContractArgs = {
    lodgingUnitTypeId?: InputMaybe<Scalars["Int"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type RootMutationCommentRentalContractArgs = {
    lodgingUnitTypeId?: InputMaybe<Scalars["Int"]>;
    message?: InputMaybe<Scalars["String"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type RootMutationCreateBlockingArgs = {
    blocking?: InputMaybe<CreateBlockingInput>;
};

export type RootMutationDeleteBlockingArgs = {
    blockingId?: InputMaybe<Scalars["Int"]>;
    lodgingId?: InputMaybe<Scalars["Int"]>;
};

export type RootMutationRejectRentalContractArgs = {
    lodgingUnitTypeId?: InputMaybe<Scalars["Int"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type RootMutationSendMessageFromCreditorArgs = {
    message?: InputMaybe<Scalars["String"]>;
};

export type RootMutationUpdateBlockingArgs = {
    blocking?: InputMaybe<CreateBlockingInput>;
    blockingId?: InputMaybe<Scalars["Int"]>;
};

export type RootQuery = {
    __typename?: "RootQuery";
    clientConfiguration: ClientConfiguration;
    clientConfigurationCreditorSpecific: ClientConfigurationCreditorSpecific;
    creditorRentalSummaryReport: CreditorRentalSummaryReport;
    intersectingBookings: Array<Booking>;
    login?: Maybe<Scalars["Boolean"]>;
    me?: Maybe<Creditor>;
};

export type RootQueryCreditorRentalSummaryReportArgs = {
    lodgingId?: InputMaybe<Scalars["Int"]>;
    year?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryIntersectingBookingsArgs = {
    intersectDate: Scalars["Date"];
    lodgingId?: InputMaybe<Scalars["Int"]>;
};

export type RootQueryLoginArgs = {
    password?: InputMaybe<Scalars["String"]>;
    persistent?: InputMaybe<Scalars["Boolean"]>;
    username?: InputMaybe<Scalars["String"]>;
};
