import React, { useCallback, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    CircularProgress
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { CreateBlockingInput } from "../../graphql-types.generated";
import useSelectedLodgingId from "../../hooks/useSelectedLodgingId";
import {
    CreditorRentalSummaryReportDocument,
    useCreateBlockingMutation,
    useDeleteBlockingMutation,
    useUpdateBlockingMutation,
} from "../../operations.generated";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { getOperationName } from "@apollo/client/utilities";
import { useTranslation } from "react-i18next";

type DialogValue = Pick<CreateBlockingInput, "from" | "to" | "comment">;
type ExistingBlocking = DialogValue & { id: number };
interface Props {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    onSubmit: (value: DialogValue, callback: () => void) => void;
    existingBlocking: ExistingBlocking | null;
    allowDeleteBlocking: boolean;
    allowCreateBlocking: boolean;
}

export function useMutateBlockingDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [allowDelete, setAllowDelete] = useState(false);
    const [allowCreate, setAllowCreate] = useState(false);
    const lodgingId = useSelectedLodgingId();
    const [createBlockingMutation] = useCreateBlockingMutation();
    const [updateBlockingMutation] = useUpdateBlockingMutation();
    const [deleteBlockingMutation] = useDeleteBlockingMutation();
    const { t } = useTranslation();

    const [existingBlocking, setExistingBlocking] = useState<ExistingBlocking | null>(null);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    async function handleSubmit(value: DialogValue, callback: () => void) {
        try {
            if (existingBlocking == null) {
                let data = await createBlockingMutation({
                    variables: { blocking: { ...value, lodgingId } },
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(CreditorRentalSummaryReportDocument)!],
                });

                if (data.data?.createBlocking) {
                    setIsOpen(false);
                } else {
                    alert(t("blockingUnableToSave"));
                }
            } else {
                let data = await updateBlockingMutation({
                    variables: { id: existingBlocking.id, blocking: { ...value, lodgingId } },
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(CreditorRentalSummaryReportDocument)!],
                });
                if (data.data?.updateBlocking) {
                    setIsOpen(false);
                } else {
                    alert(t("blockingUnableToSave"));
                }
            }
        } catch (ex) {
            alert(t("unknownServerError"));
            console.error(ex);
        } finally {
            callback();
        }
    }

    async function handleDelete() {
        try {
            if (existingBlocking) {
                let success = await deleteBlockingMutation({
                    variables: { lodgingId, blockingId: existingBlocking?.id },
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(CreditorRentalSummaryReportDocument)!],
                });
                if (success) {
                    setIsOpen(false);
                } else {
                    alert(t("unknownServerError"));
                }
            }
        } catch (ex) {
            alert(t("unknownServerError"));
            console.error(ex);
        }
    }

    const dialog = (
        <MutateBlockingDialog
            key={existingBlocking?.id || null}
            open={isOpen}
            onClose={close}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            existingBlocking={existingBlocking}
            allowDeleteBlocking={allowDelete}
            allowCreateBlocking={allowCreate}
        />
    );

    const showCreateNew = useCallback((allowCreateBlocking: boolean, allowDeleteBlocking: boolean) => {
        setExistingBlocking(null);
        setAllowCreate(allowCreateBlocking);
        setAllowDelete(allowDeleteBlocking);
        setIsOpen(true);
    }, []);

    const showEdit = useCallback((existingBlocking: ExistingBlocking, allowCreateBlocking: boolean, allowDeleteBlocking: boolean) => {
        setExistingBlocking(existingBlocking);
        setAllowCreate(allowCreateBlocking);
        setAllowDelete(allowDeleteBlocking);
        setIsOpen(true);
    }, []);

    return {
        close,
        dialog,
        showCreateNew,
        showEdit,
    };
}

function MutateBlockingDialog({
    open,
    onClose,
    onSubmit,
    onDelete,
    existingBlocking,
    allowDeleteBlocking,
    allowCreateBlocking,
}: Props) {
    const [from, setFrom] = useState<MaterialUiPickersDate>(existingBlocking?.from ?? null);
    const [to, setTo] = useState<MaterialUiPickersDate>(existingBlocking?.to ?? null);
    const [comment, setComment] = useState<string>(existingBlocking?.comment ?? "");
    const [submitted, setSubmitted] = useState(false);
    const { t } = useTranslation();

    function handleSubmit() {
        if (!submitted) {
            setSubmitted(true);
            onSubmit({ from, to, comment }, () => {
                setSubmitted(false);
            });
        }
    }

    function handleDelete() {
        // eslint-disable-next-line no-restricted-globals
        if (confirm(t("blockingAreYouSureYouWantToDelete"))) {
            onDelete();
        }
    }

    if (submitted) {
        return (
            <Dialog open={true}>
                <DialogContent>
                    <CircularProgress />
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog
            open={open}
            onClose={() => {
                onClose();
                setSubmitted(false);
            }}
            aria-labelledby="simple-dialog-title"
        >
            <DialogTitle id="simple-dialog-title">
                {existingBlocking ? t("blockingDialogTitleEdit") : t("blockingDialogTitleCreate")}
            </DialogTitle>
            {submitted ? (
                <DialogContent style={{ textAlign: "center" }}>
                    <CircularProgress />
                </DialogContent>
            ) : (
                <>
                    <DialogContent>
                        <div>
                            <DatePicker
                                label={t("from")}
                                margin="normal"
                                value={from}
                                onChange={setFrom}
                                disablePast
                                disabled={!allowCreateBlocking}
                                inputVariant="outlined"
                            />
                        </div>
                        <div>
                            <DatePicker
                                label={t("to")}
                                margin="normal"
                                value={to}
                                onChange={setTo}
                                disablePast
                                inputVariant="outlined"
                                minDate={from}
                                disabled={!allowCreateBlocking}
                                initialFocusedDate={from}
                            />
                        </div>
                        <div>
                            <TextField
                                label={t("comment")}
                                margin="normal"
                                variant="outlined"
                                multiline
                                value={comment}
                                disabled={!allowCreateBlocking}
                                onChange={(e) => setComment(e.currentTarget.value)}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {existingBlocking != null && allowDeleteBlocking && (
                            <IconButton onClick={() => handleDelete()}>
                                <DeleteForeverIcon color="error" />
                            </IconButton>
                        )}
                        <div style={{ flexGrow: 1 }}></div>
                        <Button onClick={onClose} color="primary">
                            {t("cancel")}
                        </Button>
                        {allowCreateBlocking && 
                            <Button onClick={handleSubmit} color="primary">
                                {existingBlocking == null && t("create")}
                                {existingBlocking != null && t("update")}
                            </Button>
                        }
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}
