import React, { CSSProperties, useMemo } from "react";
import { makeStyles } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import { useDateFnsLocale } from "../DateFnsContext";

const useStyles = makeStyles((theme) => ({
    root: ({
        display: "grid",
        gridTemplate: `
            'day'
            'month'
        `,
        justifyContent: "flex-start",
    } as CSSProperties) as any,
    day: ({
        gridArea: "day",
        ...theme.typography.h5,
        lineHeight: "1em",
        width: "2ch",
        textAlign: "center",
    } as CSSProperties) as any,
    month: ({
        gridArea: "month",
        textTransform: "uppercase",
        ...theme.typography.body2,
        fontWeight: "bold",
        color: theme.palette.secondary.main,
    } as CSSProperties) as any,
    weekDay: ({
        gridArea: "weekDay",
        textTransform: "uppercase",
        ...theme.typography.body2,
    } as CSSProperties) as any,
}));

export default function FancyDate({ date }: { date: string }) {
    const classes = useStyles();
    const parsedDate = useMemo(() => parseISO(date), [date]);
    const dateFnsLocale = useDateFnsLocale();

    return (
        <div className={classes.root}>
            <div className={classes.day}>{format(parsedDate, "d", { locale: dateFnsLocale })}</div>
            <div className={classes.month}>
                {format(parsedDate, "MMM", { locale: dateFnsLocale })}
            </div>
        </div>
    );
}
