import YearPicker from "../../../components/YearPicker";
import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import useSelectedYear from "../../../hooks/useSelectedYear";
import useSelectedLodgingId from "../../../hooks/useSelectedLodgingId";
import {
    CommentFragment,
    MessageFragment,
    RentalContractDocument,
    useAcceptRentalContractMutation,
    useCommentRentalContractMutation,
    useRejectRentalContractMutation,
    useRentalContractQuery,
} from "../../../operations.generated";
import { Box, CircularProgress } from "@material-ui/core";
import BottomPanel from "../../../components/BottomPanel";
import MessageList from "../../../components/MessageList";
import { TFunction, useTranslation } from "react-i18next";
import { getOperationName } from "@apollo/client/utilities";
import ConfirmModal, { ConfirmState } from "./ConfirmModal";

function mapCommentsToMessages(comments: CommentFragment[]): MessageFragment[] {
    return comments.map((comment, index) => ({
        id: index,
        createdAt: comment.timeStamp,
        createdAtDisplayValue: comment.timeStampDisplayValue,
        messageText: comment.comment,
        messageDirection: comment.commentDirection,
    }));
}

export default function RentalContractView() {
    const year = useSelectedYear();
    const lodgingId = useSelectedLodgingId();
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [commentRentalContract, commentMutation] = useCommentRentalContractMutation();
    const [acceptRentalContract] = useAcceptRentalContractMutation();
    const [rejectRentalContract] = useRejectRentalContractMutation();

    const { data, error, loading } = useRentalContractQuery({
        variables: {
            year,
            lodgingId,
        },
    });
    const [confirmState, setConfirmState] = useState<ConfirmState | null>(null);

    if (error) {
        if (error.graphQLErrors[0].extensions.data["DisplayToClient"]) {
            return <div style={{ marginTop: "20px", marginLeft: "20px"}}>{error.graphQLErrors[0].message}</div>;
        }
        return <div style={{ marginTop: "20px", marginLeft: "20px"}}>Error</div>;
    }

    if (loading || data == null) {
        return (
            <Container>
                <YearPicker />
                <Box
                    style={{
                        display: "flex",
                        marginTop: "150px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
                <BottomPanel />
            </Container>
        );
    }

    function download() {
        const pathTokens = window.location.pathname.split("/");
        const tenantId = pathTokens[1];
        const segmentation = pathTokens[2];
        window.open(
            `/${tenantId}/${segmentation}/rentalcontractpdf/show?lodgingUnitTypeId=${data.me.lodging.rentalContract.lodgingUnitTypeId}&year=${data.me.lodging.rentalContract.year}`
        );
    }

    async function sendMessage() {
        await commentRentalContract({
            variables: {
                message,
                year: data.me.lodging.rentalContract.year,
                lodgingUnitTypeId: data.me.lodging.rentalContract.lodgingUnitTypeId,
            },
            awaitRefetchQueries: true,
            refetchQueries: [getOperationName(RentalContractDocument)],
        });
        setMessage("");
    }

    function accept() {
        setConfirmState({
            confirmAction: t("accept"),
            confirmVariant: "success",
            message: t("acceptRentalContractConfirmText"),
            then: () =>
                acceptRentalContract({
                    variables: {
                        year: data.me.lodging.rentalContract.year,
                        lodgingUnitTypeId: data.me.lodging.rentalContract.lodgingUnitTypeId,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(RentalContractDocument)],
                }),
        });
    }

    function reject() {
        setConfirmState({
            confirmAction: t("reject"),
            confirmVariant: "danger",
            message: t("rejectRentalContractConfirmText"),
            then: () =>
                rejectRentalContract({
                    variables: {
                        year: data.me.lodging.rentalContract.year,
                        lodgingUnitTypeId: data.me.lodging.rentalContract.lodgingUnitTypeId,
                    },
                    awaitRefetchQueries: true,
                    refetchQueries: [getOperationName(RentalContractDocument)],
                }),
        });
    }

    const isOpen = data.me.lodging.rentalContract?.status == 1;

    return (
        <Container>
            <YearPicker />
            {!data.me.lodging.rentalContract && (
                <Row xs={1} md={2} className="g-4 mt-3">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    {t("rentalContract")} - {data.me.lodging.name}
                                </Card.Title>

                                {t("noRentalContract")}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            {data.me.lodging.rentalContract && (
                <Row xs={1} md={2} className="g-4 mt-3">
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    {t("rentalContract")} - {data.me.lodging.name}
                                </Card.Title>

                                <div>
                                    {t("status")}:{" "}
                                    {getRentalContractStatus(
                                        t,
                                        data.me.lodging.rentalContract.status
                                    )}
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <div className="d-flex gap-2">
                                    <Button variant="secondary" onClick={download}>
                                        {t("showRentalContract")}
                                    </Button>
                                    {isOpen && (
                                        <Button variant="success" onClick={() => accept()}>
                                            {t("accept")}
                                        </Button>
                                    )}
                                    {isOpen && (
                                        <Button variant="danger" onClick={() => reject()}>
                                            {t("reject")}
                                        </Button>
                                    )}
                                </div>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Card.Title>{t("comments")}</Card.Title>
                                <MessageList
                                    messages={mapCommentsToMessages(
                                        data.me.lodging.rentalContract.comments
                                    )}
                                />
                            </Card.Body>
                        </Card>
                        {isOpen && (
                            <Card className="mt-2">
                                <Card.Body>
                                    <Form.Group className="mb-3">
                                        <Form.Label>{t("message")}</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            onChange={(e) => setMessage(e.currentTarget.value)}
                                            value={message}
                                        />
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        disabled={message.length == 0 || commentMutation.loading}
                                        onClick={() => sendMessage()}
                                    >
                                        {t("send")}
                                    </Button>
                                </Card.Body>
                            </Card>
                        )}
                    </Col>
                </Row>
            )}
            <ConfirmModal confirmState={confirmState} onClose={() => setConfirmState(null)} />
            <BottomPanel />
        </Container>
    );
}

function getRentalContractStatus(t: TFunction, status: number) {
    switch (status) {
        case 1:
            return t("rentalContractStatus.AwaitingOwner");
        case 2:
            return t("rentalContractStatus.AcceptedByOwner");
        case 3:
            return t("rentalContractStatus.RejectedByOwner");
        case 4:
            return t("rentalContractStatus.AcceptedByOwner");
        case 98:
            return t("rentalContractStatus.ClosedAndEndingContract");
        case 99:
            return t("rentalContractStatus.Closed");
        default:
            return t("unknownRentalContractStatus");
    }
}
