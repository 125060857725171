import { MessageFragment } from "../operations.generated";
import styled from "styled-components";

const IngoingMessage = styled.div`
    background-color: var(--bs-gray-200);
    border-radius: 8px;
    padding: 10px 8px;
    width: 75%;
    white-space: pre-wrap;
`;

const IngoingMessageContainer = styled.div<{ paddingTop: boolean }>`
    display: flex;
    gap: 0.5rem;
    justify-content: start;
    padding-bottom: 0.5rem;
    ${(props) => (props.paddingTop ? "padding-top: 0.5rem;" : null)}
`;

const OutgoingMessage = styled.div`
    background-color: var(--bs-blue);
    border-radius: 8px;
    padding: 10px 8px;
    width: 75%;
    color: #fff;
    white-space: pre-wrap;
`;

const OutgoingMessageContainer = styled.div<{ paddingTop: boolean }>`
    display: flex;
    gap: 0.5rem;
    justify-content: end;
    padding-bottom: 0.5rem;
    ${(props) => (props.paddingTop ? "padding-top: 0.5rem;" : null)}
`;

export default function Message({message}: {message: MessageFragment}) {
    var displayMessage = message.messageText.replaceAll("<br />", "");
    if(message.messageDirection == "FROM_CREDITOR") {
        return <OutgoingMessageContainer><OutgoingMessage>{displayMessage}</OutgoingMessage></OutgoingMessageContainer>
    } else {
        return <IngoingMessageContainer><IngoingMessage>{displayMessage}</IngoingMessage></IngoingMessageContainer>
    }
}