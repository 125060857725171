import {
    Box,
    Divider,
    IconButton,
    Paper,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
    CreditorRentalSummaryReportQuery,
    CreditorRentalSummaryReportReservationFragment,
    useMeQuery,
} from "operations.generated";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import FancyPeriod from "../../components/FancyPeriod";
import GreenCheckItem from "../../components/GreenCheckItem";
import BookingTimeline from "../../components/BookingTimeline";
import Flag from "react-world-flags";
import { useTranslation } from "react-i18next";

type Reservation =
    CreditorRentalSummaryReportQuery["creditorRentalSummaryReport"]["reservations"][0];

interface DetailReservationViewProps {
    detailReservation: Reservation;
    onClose: () => void;
}

const useStyles = makeStyles((theme) => ({
    customerInfo: {
        backgroundColor: "#EFEFEF",
        padding: theme.spacing(2),
        display: "grid",
        gridTemplateColumns: "min-content 1fr",
        gap: theme.spacing(2),
        alignItems: "center",
    } as React.CSSProperties as any,
    checkMarksContainer: {
        display: "grid",
        gap: theme.spacing(1),
        gridAutoFlow: "row",
    } as React.CSSProperties as any,
    ownerPrice: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(2),
    } as React.CSSProperties as any,
}));

export function useDetailReservationDrawer() {
    const [detailReservation, setDetailReservation] =
        useState<CreditorRentalSummaryReportReservationFragment | null>(null);
    const [detailOpen, setDetailOpen] = useState<boolean>(false);
    const { data } = useMeQuery();

    const drawer = (
        <SwipeableDrawer
            anchor={"right"}
            open={detailOpen}
            onClose={() => setDetailOpen(false)}
            onOpen={() => {}}
        >
            {detailReservation && data && (
                <DetailReservationDrawerView
                    detailReservation={detailReservation}
                    onClose={() => setDetailOpen(false)}
                />
            )}
        </SwipeableDrawer>
    );

    const close = useCallback(() => {
        setDetailOpen(false);
    }, []);

    const setDetailReservationAndOpen = useCallback(
        (reservation: CreditorRentalSummaryReportReservationFragment) => {
            setDetailReservation(reservation);
            setDetailOpen(true);
        },
        []
    );

    return {
        setDetailReservationAndOpen,
        close,
        drawer,
    };
}

export function DetailReservationDrawerView({
    detailReservation,
    onClose,
}: DetailReservationViewProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const guestText = [];

    // eslint-disable-next-line no-self-compare
    if (detailReservation?.adults ?? 0 > 0) {
        guestText.push(t("adults", { count: detailReservation.adults! }));
    }

    // eslint-disable-next-line no-self-compare
    if (detailReservation?.children ?? 0 > 0) {
        guestText.push(t("children", { count: detailReservation.children! }));
    }

    // eslint-disable-next-line no-self-compare
    if (detailReservation?.pets ?? 0 > 0) {
        guestText.push(t("pets", { count: detailReservation.pets! }));
    }

    let country = detailReservation.reservationDetails[0].country;
    let customerName = detailReservation.reservationDetails[0].customerName;

    let isConfirmed = detailReservation.orderItems.some(
        (r) => r.orderItemType === "Invoice" && !r.isCancelled
    );
    let isPaid = detailReservation.balance === 0 && detailReservation.principalSum > 0;
    let partiallyPaid = detailReservation.paidToCreditor > 0 && detailReservation.balance !== 0;

    return (
        <>
            <Toolbar>
                <Box flexGrow="1">
                    <Typography variant="h4">
                        {detailReservation.reservationStatus == "OPTION"
                            ? t("option")
                            : t("reservation")}{" "}
                        {detailReservation.reservationId}
                    </Typography>
                </Box>
                <IconButton onClick={() => onClose()}>
                    <CloseIcon fontSize="large" />
                </IconButton>
            </Toolbar>
            <Divider />

            <Paper elevation={0} square className={classes.customerInfo}>
                <Flag
                    height={50}
                    code={country}
                    fallback={(<Typography variant="h2">({country})</Typography>) as any}
                />
                <div>
                    <Typography variant={"subtitle1"} color="textPrimary">
                        {customerName}
                    </Typography>
                    <Typography variant={"body1"} color="textSecondary">
                        {guestText.join(", ")}
                    </Typography>
                </div>
            </Paper>
            <Box>
                <Paper elevation={1} square>
                    <Box pt={2} pl={2} pb={2} pr={2}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <div>
                                <FancyPeriod
                                    arrivalDate={detailReservation.arrivalDate}
                                    departureDate={detailReservation.departureDate}
                                />
                            </div>
                            <div className={classes.checkMarksContainer}>
                                {isConfirmed && (
                                    <GreenCheckItem label={t("reservationConfirmed")} />
                                )}
                                {isPaid && <GreenCheckItem label={t("paid")} />}
                                {partiallyPaid && (
                                    <GreenCheckItem
                                        label={`${t("partiallyPaid")} (${
                                            detailReservation.paidToCreditorDisplayValue
                                        })`}
                                    />
                                )}
                            </div>
                        </Box>
                    </Box>
                </Paper>
                {detailReservation.reservationStatus != "OPTION" && (
                    <Paper elevation={1} square>
                        <Paper elevation={2} square className={classes.ownerPrice}>
                            <Typography variant="h4">
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>{t("ownerPrice")}</div>
                                    <div>{detailReservation.principalSumDisplayValue}</div>
                                </div>
                            </Typography>
                        </Paper>
                        <div>
                            <Typography variant="body1">
                                <Box
                                    pl={2}
                                    pr={2}
                                    pt={2}
                                    pb={1}
                                    style={{
                                        backgroundColor: "#EFEFEF",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>{t("rental")}</div>
                                    <div>{detailReservation.bookingSumDisplayValue}</div>
                                </Box>
                                <Box
                                    pl={2}
                                    pr={2}
                                    pt={1}
                                    pb={2}
                                    style={{
                                        backgroundColor: "#EFEFEF",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div>{t("consumption")}</div>
                                    <div>{detailReservation.consumptionSumDisplayValue}</div>
                                </Box>
                            </Typography>
                        </div>
                    </Paper>
                )}
                <Box pt={2} pl={2} pr={2}>
                    <Typography variant="h6">{t("details")}</Typography>
                </Box>
                <BookingTimeline data={detailReservation} />
            </Box>
        </>
    );
}
