import { createContext, useContext } from "react";
import { ClientInformationQuery } from "./operations.generated";

export type ClientConfiguration = ClientInformationQuery["clientConfiguration"];

const context = createContext<ClientConfiguration>(null as any);

export const ClientConfigurationContext = context.Provider;

export function useClientConfiguration() {
    return useContext<ClientConfiguration>(context);
}
