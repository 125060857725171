import React from "react";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
} from "@material-ui/core";
import { CreditorRentalSummaryReportQuery } from "operations.generated";
import { useTranslation } from "react-i18next";

export default function ExpensesList({ data }: { data: CreditorRentalSummaryReportQuery }) {
    const { t } = useTranslation();

    return (
        <Paper>
            <List subheader={<ListSubheader>{t("regulations")}</ListSubheader>}>
                {data.creditorRentalSummaryReport.expenses.map((e, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <Divider />}
                        <ListItem key={index} style={{ minHeight: "72px" }}>
                            <ListItemText secondary={e.orderDateDisplayValue}>
                                <Box display="flex" justifyContent="space-between">
                                    <span>{e.description}</span>
                                    <span>{e.principalAmountDisplayValue}</span>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    </React.Fragment>
                ))}
                {data.creditorRentalSummaryReport.expenses.length === 0 && (
                    <ListItem>
                        <ListItemText>{t("noExpenses")}</ListItemText>
                    </ListItem>
                )}
            </List>
        </Paper>
    );
}
