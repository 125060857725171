import * as Types from "./graphql-types.generated";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type LoginQueryVariables = Types.Exact<{
    username: Types.Scalars["String"];
    password: Types.Scalars["String"];
    persistent: Types.Scalars["Boolean"];
}>;

export type LoginQuery = { __typename?: "RootQuery"; login?: boolean | null };

export type ClientInformationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ClientInformationQuery = {
    __typename?: "RootQuery";
    clientConfiguration: {
        __typename?: "ClientConfiguration";
        appTitle: string;
        blockingTypeId: number;
        defaultCulture: string;
        headerTextColor?: string | null;
        headerBackgroundColor?: string | null;
        logoUrl?: string | null;
        primaryColor?: string | null;
        secondaryColor?: string | null;
        showMessages: boolean;
        showRentalContracts: boolean;
        blockingGroupColors: Array<{ __typename?: "KeyValue"; key: string; value: string }>;
        blockingGroupTranslations: Array<{ __typename?: "KeyValue"; key: string; value: string }>;
    };
};

export type CreditorConfigurationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CreditorConfigurationQuery = {
    __typename?: "RootQuery";
    clientConfigurationCreditorSpecific: {
        __typename?: "ClientConfigurationCreditorSpecific";
        allowCreateBlocking: boolean;
        allowDeleteBlocking: boolean;
    };
};

export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
    __typename?: "RootQuery";
    me?: {
        __typename?: "Creditor";
        id: number;
        name: string;
        lodgings: Array<{
            __typename?: "Lodging";
            id: number;
            name: string;
            address?: string | null;
            primaryImage?: { __typename?: "Image"; locationTemplate: string } | null;
        }>;
        messages: Array<{
            __typename?: "Message";
            id: number;
            createdAt: any;
            createdAtDisplayValue?: string | null;
            messageText: string;
            messageDirection: Types.MessageDirection;
        }>;
    } | null;
};

export type MessageFragment = {
    __typename?: "Message";
    id: number;
    createdAt: any;
    createdAtDisplayValue?: string | null;
    messageText: string;
    messageDirection: Types.MessageDirection;
};

export type LodgingInformationFragment = {
    __typename?: "Lodging";
    id: number;
    name: string;
    address?: string | null;
    primaryImage?: { __typename?: "Image"; locationTemplate: string } | null;
};

export type CreditorRentalSummaryReportBlockingFragment = {
    __typename?: "CreditorRentalSummaryReportBlocking";
    blockedFrom: any;
    blockedTo: any;
    blockingGroup: string;
    id: number;
    publicNote?: string | null;
    blockingGroupId?: number | null;
};

export type CreditorRentalSummaryReportReservationFragment = {
    __typename?: "CreditorRentalSummaryReportReservation";
    reservationId: number;
    lodgingId: number;
    arrivalDate?: any | null;
    departureDate?: any | null;
    arrivalDisplayValue: string;
    departureDisplayValue: string;
    nights?: number | null;
    adults?: number | null;
    children?: number | null;
    pets?: number | null;
    bookingSumDisplayValue?: string | null;
    consumptionSumDisplayValue?: string | null;
    principalSumDisplayValue?: string | null;
    paidToCreditorDisplayValue?: string | null;
    balanceDisplayValue?: string | null;
    balance: any;
    paidToCreditor: any;
    principalSum: any;
    consumptionSum?: any | null;
    bookingSum: any;
    reservationStatus: Types.ReservationStatus;
    reservationDetails: Array<{
        __typename?: "CreditorRentalSummaryReportReservationDetail";
        orderItemId: number;
        orderDateDisplayValue: string;
        customerName: string;
        country: string;
        invoiceLineDescription: string;
        orderItemType: string;
        arrivalDisplayValue?: string | null;
        departureDisplayValue?: string | null;
        principalSumDisplayValue?: string | null;
    }>;
    orderItems: Array<{
        __typename?: "CreditorRentalSummaryReportOrderItem";
        isBookingInvoice: boolean;
        orderItemType: string;
        orderDateDisplayValue?: string | null;
        isCancelled: boolean;
        lines: Array<{
            __typename?: "CreditorRentalSummaryReportReservationDetail";
            invoiceLineDescription: string;
            principalSumDisplayValue?: string | null;
        }>;
    }>;
};

export type CreditorRentalSummaryReportQueryVariables = Types.Exact<{
    year: Types.Scalars["Int"];
    lodgingId: Types.Scalars["Int"];
}>;

export type CreditorRentalSummaryReportQuery = {
    __typename?: "RootQuery";
    me?: {
        __typename?: "Creditor";
        lodgings: Array<{
            __typename?: "Lodging";
            id: number;
            name: string;
            address?: string | null;
            primaryImage?: { __typename?: "Image"; locationTemplate: string } | null;
        }>;
    } | null;
    creditorRentalSummaryReport: {
        __typename?: "CreditorRentalSummaryReport";
        year: number;
        bookingSumDisplayValue?: string | null;
        consumptionSumDisplayValue?: string | null;
        paidToCreditorSumDisplayValue?: string | null;
        principalSumDisplayValue?: string | null;
        balanceDisplayValue?: string | null;
        expensesSumDisplayValue?: string | null;
        blockings: Array<{
            __typename?: "CreditorRentalSummaryReportBlocking";
            blockedFrom: any;
            blockedTo: any;
            blockingGroup: string;
            id: number;
            publicNote?: string | null;
            blockingGroupId?: number | null;
        }>;
        reservations: Array<{
            __typename?: "CreditorRentalSummaryReportReservation";
            reservationId: number;
            lodgingId: number;
            arrivalDate?: any | null;
            departureDate?: any | null;
            arrivalDisplayValue: string;
            departureDisplayValue: string;
            nights?: number | null;
            adults?: number | null;
            children?: number | null;
            pets?: number | null;
            bookingSumDisplayValue?: string | null;
            consumptionSumDisplayValue?: string | null;
            principalSumDisplayValue?: string | null;
            paidToCreditorDisplayValue?: string | null;
            balanceDisplayValue?: string | null;
            balance: any;
            paidToCreditor: any;
            principalSum: any;
            consumptionSum?: any | null;
            bookingSum: any;
            reservationStatus: Types.ReservationStatus;
            reservationDetails: Array<{
                __typename?: "CreditorRentalSummaryReportReservationDetail";
                orderItemId: number;
                orderDateDisplayValue: string;
                customerName: string;
                country: string;
                invoiceLineDescription: string;
                orderItemType: string;
                arrivalDisplayValue?: string | null;
                departureDisplayValue?: string | null;
                principalSumDisplayValue?: string | null;
            }>;
            orderItems: Array<{
                __typename?: "CreditorRentalSummaryReportOrderItem";
                isBookingInvoice: boolean;
                orderItemType: string;
                orderDateDisplayValue?: string | null;
                isCancelled: boolean;
                lines: Array<{
                    __typename?: "CreditorRentalSummaryReportReservationDetail";
                    invoiceLineDescription: string;
                    principalSumDisplayValue?: string | null;
                }>;
            }>;
        }>;
        expenses: Array<{
            __typename?: "CreditorRentalSummaryReportExpense";
            orderItemId: number;
            description: string;
            orderDateDisplayValue?: string | null;
            principalAmountDisplayValue?: string | null;
        }>;
        chartData: Array<{ __typename?: "ChartData"; month: string; amount?: any | null }>;
    };
};

export type RentalContractQueryVariables = Types.Exact<{
    lodgingId: Types.Scalars["Int"];
    year: Types.Scalars["Int"];
}>;

export type RentalContractQuery = {
    __typename?: "RootQuery";
    me?: {
        __typename?: "Creditor";
        lodging?: {
            __typename?: "Lodging";
            name: string;
            rentalContract?: {
                __typename?: "RentalContractStatus";
                id: number;
                revision: number;
                status: number;
                year: number;
                lodgingUnitTypeId: number;
                comments?: Array<{
                    __typename?: "RentalContractComment";
                    comment: string;
                    commentDirection?: Types.MessageDirection | null;
                    senderName: string;
                    timeStamp: any;
                    timeStampDisplayValue?: string | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};

export type OverlapsQueryVariables = Types.Exact<{
    intersectDate: Types.Scalars["Date"];
    lodgingId?: Types.InputMaybe<Types.Scalars["Int"]>;
}>;

export type OverlapsQuery = {
    __typename?: "RootQuery";
    intersectingBookings: Array<{
        __typename?: "Booking";
        arrivalDate: any;
        departureDate: any;
        lodgingUnitTypeId: number;
        reservationId: number;
    }>;
};

export type CommentFragment = {
    __typename?: "RentalContractComment";
    comment: string;
    commentDirection?: Types.MessageDirection | null;
    senderName: string;
    timeStamp: any;
    timeStampDisplayValue?: string | null;
};

export type CreateBlockingMutationVariables = Types.Exact<{
    blocking: Types.CreateBlockingInput;
}>;

export type CreateBlockingMutation = {
    __typename?: "RootMutation";
    createBlocking?: boolean | null;
};

export type UpdateBlockingMutationVariables = Types.Exact<{
    id: Types.Scalars["Int"];
    blocking: Types.CreateBlockingInput;
}>;

export type UpdateBlockingMutation = {
    __typename?: "RootMutation";
    updateBlocking?: boolean | null;
};

export type DeleteBlockingMutationVariables = Types.Exact<{
    lodgingId: Types.Scalars["Int"];
    blockingId: Types.Scalars["Int"];
}>;

export type DeleteBlockingMutation = {
    __typename?: "RootMutation";
    deleteBlocking?: boolean | null;
};

export type SendMessageFromCreditorMutationVariables = Types.Exact<{
    message: Types.Scalars["String"];
}>;

export type SendMessageFromCreditorMutation = {
    __typename?: "RootMutation";
    sendMessageFromCreditor?: boolean | null;
};

export type AcceptRentalContractMutationVariables = Types.Exact<{
    lodgingUnitTypeId: Types.Scalars["Int"];
    year: Types.Scalars["Int"];
}>;

export type AcceptRentalContractMutation = {
    __typename?: "RootMutation";
    acceptRentalContract?: boolean | null;
};

export type RejectRentalContractMutationVariables = Types.Exact<{
    lodgingUnitTypeId: Types.Scalars["Int"];
    year: Types.Scalars["Int"];
}>;

export type RejectRentalContractMutation = {
    __typename?: "RootMutation";
    rejectRentalContract?: boolean | null;
};

export type CommentRentalContractMutationVariables = Types.Exact<{
    lodgingUnitTypeId: Types.Scalars["Int"];
    year: Types.Scalars["Int"];
    message: Types.Scalars["String"];
}>;

export type CommentRentalContractMutation = {
    __typename?: "RootMutation";
    commentRentalContract?: boolean | null;
};

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: "RootMutation"; logout?: boolean | null };

export const MessageFragmentDoc = gql`
    fragment message on Message {
        id
        createdAt
        createdAtDisplayValue(format: "d")
        messageText
        messageDirection
    }
`;
export const LodgingInformationFragmentDoc = gql`
    fragment lodgingInformation on Lodging {
        id
        name
        primaryImage {
            locationTemplate
        }
        address
    }
`;
export const CreditorRentalSummaryReportBlockingFragmentDoc = gql`
    fragment creditorRentalSummaryReportBlocking on CreditorRentalSummaryReportBlocking {
        blockedFrom
        blockedTo
        blockingGroup
        id
        publicNote
        blockingGroupId
    }
`;
export const CreditorRentalSummaryReportReservationFragmentDoc = gql`
    fragment creditorRentalSummaryReportReservation on CreditorRentalSummaryReportReservation {
        reservationId
        lodgingId
        arrivalDate
        departureDate
        arrivalDisplayValue(format: "dd-MM")
        departureDisplayValue(format: "dd-MM")
        nights
        adults
        children
        pets
        bookingSumDisplayValue(format: "#,##0.00")
        consumptionSumDisplayValue(format: "#,##0.00")
        principalSumDisplayValue(format: "#,##0.00")
        paidToCreditorDisplayValue(format: "#,##0.00")
        balanceDisplayValue(format: "#,##0.00")
        balance
        paidToCreditor
        principalSum
        consumptionSum
        bookingSum
        reservationStatus
        reservationDetails {
            orderItemId
            orderDateDisplayValue(format: "dd-MM-yyyy")
            customerName
            country
            invoiceLineDescription
            orderItemType
            arrivalDisplayValue(format: "dd-MM-yyyy")
            departureDisplayValue(format: "dd-MM-yyyy")
            principalSumDisplayValue(format: "#,##0.00")
        }
        orderItems {
            isBookingInvoice
            orderItemType
            orderDateDisplayValue(format: "dd MMM")
            isCancelled
            lines {
                invoiceLineDescription
                principalSumDisplayValue(format: "#,##0.00")
            }
        }
    }
`;
export const CommentFragmentDoc = gql`
    fragment comment on RentalContractComment {
        comment
        commentDirection
        senderName
        timeStamp
        timeStampDisplayValue(format: "d")
    }
`;
export const LoginDocument = gql`
    query login($username: String!, $password: String!, $persistent: Boolean!) {
        login(username: $username, password: $password, persistent: $persistent)
    }
`;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      persistent: // value for 'persistent'
 *   },
 * });
 */
export function useLoginQuery(
    baseOptions: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
}
export function useLoginLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
}
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const ClientInformationDocument = gql`
    query clientInformation {
        clientConfiguration {
            appTitle
            blockingTypeId
            defaultCulture
            headerTextColor
            headerBackgroundColor
            logoUrl
            primaryColor
            secondaryColor
            blockingGroupColors {
                key
                value
            }
            blockingGroupTranslations {
                key
                value
            }
            showMessages
            showRentalContracts
        }
    }
`;

/**
 * __useClientInformationQuery__
 *
 * To run a query within a React component, call `useClientInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientInformationQuery(
    baseOptions?: Apollo.QueryHookOptions<ClientInformationQuery, ClientInformationQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ClientInformationQuery, ClientInformationQueryVariables>(
        ClientInformationDocument,
        options
    );
}
export function useClientInformationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ClientInformationQuery,
        ClientInformationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<ClientInformationQuery, ClientInformationQueryVariables>(
        ClientInformationDocument,
        options
    );
}
export type ClientInformationQueryHookResult = ReturnType<typeof useClientInformationQuery>;
export type ClientInformationLazyQueryHookResult = ReturnType<typeof useClientInformationLazyQuery>;
export type ClientInformationQueryResult = Apollo.QueryResult<
    ClientInformationQuery,
    ClientInformationQueryVariables
>;
export const CreditorConfigurationDocument = gql`
    query creditorConfiguration {
        clientConfigurationCreditorSpecific {
            allowCreateBlocking
            allowDeleteBlocking
        }
    }
`;

/**
 * __useCreditorConfigurationQuery__
 *
 * To run a query within a React component, call `useCreditorConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditorConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditorConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useCreditorConfigurationQuery(
    baseOptions?: Apollo.QueryHookOptions<
        CreditorConfigurationQuery,
        CreditorConfigurationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<CreditorConfigurationQuery, CreditorConfigurationQueryVariables>(
        CreditorConfigurationDocument,
        options
    );
}
export function useCreditorConfigurationLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CreditorConfigurationQuery,
        CreditorConfigurationQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<CreditorConfigurationQuery, CreditorConfigurationQueryVariables>(
        CreditorConfigurationDocument,
        options
    );
}
export type CreditorConfigurationQueryHookResult = ReturnType<typeof useCreditorConfigurationQuery>;
export type CreditorConfigurationLazyQueryHookResult = ReturnType<
    typeof useCreditorConfigurationLazyQuery
>;
export type CreditorConfigurationQueryResult = Apollo.QueryResult<
    CreditorConfigurationQuery,
    CreditorConfigurationQueryVariables
>;
export const MeDocument = gql`
    query me {
        me {
            id
            name
            lodgings {
                ...lodgingInformation
            }
            messages {
                ...message
            }
        }
    }
    ${LodgingInformationFragmentDoc}
    ${MessageFragmentDoc}
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CreditorRentalSummaryReportDocument = gql`
    query creditorRentalSummaryReport($year: Int!, $lodgingId: Int!) {
        me {
            lodgings {
                ...lodgingInformation
            }
        }
        creditorRentalSummaryReport(year: $year, lodgingId: $lodgingId) {
            year
            bookingSumDisplayValue(format: "#,##0.00")
            consumptionSumDisplayValue(format: "#,##0.00")
            paidToCreditorSumDisplayValue(format: "#,##0.00")
            principalSumDisplayValue(format: "#,##0.00")
            balanceDisplayValue(format: "#,##0.00")
            expensesSumDisplayValue(format: "#,##0.00")
            blockings {
                ...creditorRentalSummaryReportBlocking
            }
            reservations {
                ...creditorRentalSummaryReportReservation
            }
            expenses {
                orderItemId
                description
                orderDateDisplayValue(format: "dd-MM-yyyy")
                principalAmountDisplayValue(format: "#,##0.00")
            }
            chartData {
                month
                amount
            }
        }
    }
    ${LodgingInformationFragmentDoc}
    ${CreditorRentalSummaryReportBlockingFragmentDoc}
    ${CreditorRentalSummaryReportReservationFragmentDoc}
`;

/**
 * __useCreditorRentalSummaryReportQuery__
 *
 * To run a query within a React component, call `useCreditorRentalSummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditorRentalSummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditorRentalSummaryReportQuery({
 *   variables: {
 *      year: // value for 'year'
 *      lodgingId: // value for 'lodgingId'
 *   },
 * });
 */
export function useCreditorRentalSummaryReportQuery(
    baseOptions: Apollo.QueryHookOptions<
        CreditorRentalSummaryReportQuery,
        CreditorRentalSummaryReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        CreditorRentalSummaryReportQuery,
        CreditorRentalSummaryReportQueryVariables
    >(CreditorRentalSummaryReportDocument, options);
}
export function useCreditorRentalSummaryReportLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        CreditorRentalSummaryReportQuery,
        CreditorRentalSummaryReportQueryVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        CreditorRentalSummaryReportQuery,
        CreditorRentalSummaryReportQueryVariables
    >(CreditorRentalSummaryReportDocument, options);
}
export type CreditorRentalSummaryReportQueryHookResult = ReturnType<
    typeof useCreditorRentalSummaryReportQuery
>;
export type CreditorRentalSummaryReportLazyQueryHookResult = ReturnType<
    typeof useCreditorRentalSummaryReportLazyQuery
>;
export type CreditorRentalSummaryReportQueryResult = Apollo.QueryResult<
    CreditorRentalSummaryReportQuery,
    CreditorRentalSummaryReportQueryVariables
>;
export const RentalContractDocument = gql`
    query RentalContract($lodgingId: Int!, $year: Int!) {
        me {
            lodging(lodgingId: $lodgingId) {
                name
                rentalContract(year: $year) {
                    comments {
                        ...comment
                    }
                    id
                    revision
                    status
                    year
                    lodgingUnitTypeId
                }
            }
        }
    }
    ${CommentFragmentDoc}
`;

/**
 * __useRentalContractQuery__
 *
 * To run a query within a React component, call `useRentalContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useRentalContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRentalContractQuery({
 *   variables: {
 *      lodgingId: // value for 'lodgingId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRentalContractQuery(
    baseOptions: Apollo.QueryHookOptions<RentalContractQuery, RentalContractQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<RentalContractQuery, RentalContractQueryVariables>(
        RentalContractDocument,
        options
    );
}
export function useRentalContractLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<RentalContractQuery, RentalContractQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<RentalContractQuery, RentalContractQueryVariables>(
        RentalContractDocument,
        options
    );
}
export type RentalContractQueryHookResult = ReturnType<typeof useRentalContractQuery>;
export type RentalContractLazyQueryHookResult = ReturnType<typeof useRentalContractLazyQuery>;
export type RentalContractQueryResult = Apollo.QueryResult<
    RentalContractQuery,
    RentalContractQueryVariables
>;
export const OverlapsDocument = gql`
    query overlaps($intersectDate: Date!, $lodgingId: Int) {
        intersectingBookings(intersectDate: $intersectDate, lodgingId: $lodgingId) {
            arrivalDate
            departureDate
            lodgingUnitTypeId
            reservationId
        }
    }
`;

/**
 * __useOverlapsQuery__
 *
 * To run a query within a React component, call `useOverlapsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverlapsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverlapsQuery({
 *   variables: {
 *      intersectDate: // value for 'intersectDate'
 *      lodgingId: // value for 'lodgingId'
 *   },
 * });
 */
export function useOverlapsQuery(
    baseOptions: Apollo.QueryHookOptions<OverlapsQuery, OverlapsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<OverlapsQuery, OverlapsQueryVariables>(OverlapsDocument, options);
}
export function useOverlapsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<OverlapsQuery, OverlapsQueryVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<OverlapsQuery, OverlapsQueryVariables>(OverlapsDocument, options);
}
export type OverlapsQueryHookResult = ReturnType<typeof useOverlapsQuery>;
export type OverlapsLazyQueryHookResult = ReturnType<typeof useOverlapsLazyQuery>;
export type OverlapsQueryResult = Apollo.QueryResult<OverlapsQuery, OverlapsQueryVariables>;
export const CreateBlockingDocument = gql`
    mutation createBlocking($blocking: CreateBlockingInput!) {
        createBlocking(blocking: $blocking)
    }
`;
export type CreateBlockingMutationFn = Apollo.MutationFunction<
    CreateBlockingMutation,
    CreateBlockingMutationVariables
>;

/**
 * __useCreateBlockingMutation__
 *
 * To run a mutation, you first call `useCreateBlockingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockingMutation, { data, loading, error }] = useCreateBlockingMutation({
 *   variables: {
 *      blocking: // value for 'blocking'
 *   },
 * });
 */
export function useCreateBlockingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateBlockingMutation,
        CreateBlockingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<CreateBlockingMutation, CreateBlockingMutationVariables>(
        CreateBlockingDocument,
        options
    );
}
export type CreateBlockingMutationHookResult = ReturnType<typeof useCreateBlockingMutation>;
export type CreateBlockingMutationResult = Apollo.MutationResult<CreateBlockingMutation>;
export type CreateBlockingMutationOptions = Apollo.BaseMutationOptions<
    CreateBlockingMutation,
    CreateBlockingMutationVariables
>;
export const UpdateBlockingDocument = gql`
    mutation updateBlocking($id: Int!, $blocking: CreateBlockingInput!) {
        updateBlocking(blockingId: $id, blocking: $blocking)
    }
`;
export type UpdateBlockingMutationFn = Apollo.MutationFunction<
    UpdateBlockingMutation,
    UpdateBlockingMutationVariables
>;

/**
 * __useUpdateBlockingMutation__
 *
 * To run a mutation, you first call `useUpdateBlockingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBlockingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBlockingMutation, { data, loading, error }] = useUpdateBlockingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      blocking: // value for 'blocking'
 *   },
 * });
 */
export function useUpdateBlockingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateBlockingMutation,
        UpdateBlockingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateBlockingMutation, UpdateBlockingMutationVariables>(
        UpdateBlockingDocument,
        options
    );
}
export type UpdateBlockingMutationHookResult = ReturnType<typeof useUpdateBlockingMutation>;
export type UpdateBlockingMutationResult = Apollo.MutationResult<UpdateBlockingMutation>;
export type UpdateBlockingMutationOptions = Apollo.BaseMutationOptions<
    UpdateBlockingMutation,
    UpdateBlockingMutationVariables
>;
export const DeleteBlockingDocument = gql`
    mutation deleteBlocking($lodgingId: Int!, $blockingId: Int!) {
        deleteBlocking(lodgingId: $lodgingId, blockingId: $blockingId)
    }
`;
export type DeleteBlockingMutationFn = Apollo.MutationFunction<
    DeleteBlockingMutation,
    DeleteBlockingMutationVariables
>;

/**
 * __useDeleteBlockingMutation__
 *
 * To run a mutation, you first call `useDeleteBlockingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockingMutation, { data, loading, error }] = useDeleteBlockingMutation({
 *   variables: {
 *      lodgingId: // value for 'lodgingId'
 *      blockingId: // value for 'blockingId'
 *   },
 * });
 */
export function useDeleteBlockingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        DeleteBlockingMutation,
        DeleteBlockingMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<DeleteBlockingMutation, DeleteBlockingMutationVariables>(
        DeleteBlockingDocument,
        options
    );
}
export type DeleteBlockingMutationHookResult = ReturnType<typeof useDeleteBlockingMutation>;
export type DeleteBlockingMutationResult = Apollo.MutationResult<DeleteBlockingMutation>;
export type DeleteBlockingMutationOptions = Apollo.BaseMutationOptions<
    DeleteBlockingMutation,
    DeleteBlockingMutationVariables
>;
export const SendMessageFromCreditorDocument = gql`
    mutation sendMessageFromCreditor($message: String!) {
        sendMessageFromCreditor(message: $message)
    }
`;
export type SendMessageFromCreditorMutationFn = Apollo.MutationFunction<
    SendMessageFromCreditorMutation,
    SendMessageFromCreditorMutationVariables
>;

/**
 * __useSendMessageFromCreditorMutation__
 *
 * To run a mutation, you first call `useSendMessageFromCreditorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageFromCreditorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageFromCreditorMutation, { data, loading, error }] = useSendMessageFromCreditorMutation({
 *   variables: {
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendMessageFromCreditorMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SendMessageFromCreditorMutation,
        SendMessageFromCreditorMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SendMessageFromCreditorMutation,
        SendMessageFromCreditorMutationVariables
    >(SendMessageFromCreditorDocument, options);
}
export type SendMessageFromCreditorMutationHookResult = ReturnType<
    typeof useSendMessageFromCreditorMutation
>;
export type SendMessageFromCreditorMutationResult =
    Apollo.MutationResult<SendMessageFromCreditorMutation>;
export type SendMessageFromCreditorMutationOptions = Apollo.BaseMutationOptions<
    SendMessageFromCreditorMutation,
    SendMessageFromCreditorMutationVariables
>;
export const AcceptRentalContractDocument = gql`
    mutation acceptRentalContract($lodgingUnitTypeId: Int!, $year: Int!) {
        acceptRentalContract(lodgingUnitTypeId: $lodgingUnitTypeId, year: $year)
    }
`;
export type AcceptRentalContractMutationFn = Apollo.MutationFunction<
    AcceptRentalContractMutation,
    AcceptRentalContractMutationVariables
>;

/**
 * __useAcceptRentalContractMutation__
 *
 * To run a mutation, you first call `useAcceptRentalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptRentalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptRentalContractMutation, { data, loading, error }] = useAcceptRentalContractMutation({
 *   variables: {
 *      lodgingUnitTypeId: // value for 'lodgingUnitTypeId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useAcceptRentalContractMutation(
    baseOptions?: Apollo.MutationHookOptions<
        AcceptRentalContractMutation,
        AcceptRentalContractMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<AcceptRentalContractMutation, AcceptRentalContractMutationVariables>(
        AcceptRentalContractDocument,
        options
    );
}
export type AcceptRentalContractMutationHookResult = ReturnType<
    typeof useAcceptRentalContractMutation
>;
export type AcceptRentalContractMutationResult =
    Apollo.MutationResult<AcceptRentalContractMutation>;
export type AcceptRentalContractMutationOptions = Apollo.BaseMutationOptions<
    AcceptRentalContractMutation,
    AcceptRentalContractMutationVariables
>;
export const RejectRentalContractDocument = gql`
    mutation rejectRentalContract($lodgingUnitTypeId: Int!, $year: Int!) {
        rejectRentalContract(lodgingUnitTypeId: $lodgingUnitTypeId, year: $year)
    }
`;
export type RejectRentalContractMutationFn = Apollo.MutationFunction<
    RejectRentalContractMutation,
    RejectRentalContractMutationVariables
>;

/**
 * __useRejectRentalContractMutation__
 *
 * To run a mutation, you first call `useRejectRentalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRentalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRentalContractMutation, { data, loading, error }] = useRejectRentalContractMutation({
 *   variables: {
 *      lodgingUnitTypeId: // value for 'lodgingUnitTypeId'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useRejectRentalContractMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RejectRentalContractMutation,
        RejectRentalContractMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<RejectRentalContractMutation, RejectRentalContractMutationVariables>(
        RejectRentalContractDocument,
        options
    );
}
export type RejectRentalContractMutationHookResult = ReturnType<
    typeof useRejectRentalContractMutation
>;
export type RejectRentalContractMutationResult =
    Apollo.MutationResult<RejectRentalContractMutation>;
export type RejectRentalContractMutationOptions = Apollo.BaseMutationOptions<
    RejectRentalContractMutation,
    RejectRentalContractMutationVariables
>;
export const CommentRentalContractDocument = gql`
    mutation commentRentalContract($lodgingUnitTypeId: Int!, $year: Int!, $message: String!) {
        commentRentalContract(lodgingUnitTypeId: $lodgingUnitTypeId, year: $year, message: $message)
    }
`;
export type CommentRentalContractMutationFn = Apollo.MutationFunction<
    CommentRentalContractMutation,
    CommentRentalContractMutationVariables
>;

/**
 * __useCommentRentalContractMutation__
 *
 * To run a mutation, you first call `useCommentRentalContractMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentRentalContractMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentRentalContractMutation, { data, loading, error }] = useCommentRentalContractMutation({
 *   variables: {
 *      lodgingUnitTypeId: // value for 'lodgingUnitTypeId'
 *      year: // value for 'year'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCommentRentalContractMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CommentRentalContractMutation,
        CommentRentalContractMutationVariables
    >
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CommentRentalContractMutation,
        CommentRentalContractMutationVariables
    >(CommentRentalContractDocument, options);
}
export type CommentRentalContractMutationHookResult = ReturnType<
    typeof useCommentRentalContractMutation
>;
export type CommentRentalContractMutationResult =
    Apollo.MutationResult<CommentRentalContractMutation>;
export type CommentRentalContractMutationOptions = Apollo.BaseMutationOptions<
    CommentRentalContractMutation,
    CommentRentalContractMutationVariables
>;
export const LogoutDocument = gql`
    mutation Logout {
        logout
    }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    LogoutMutation,
    LogoutMutationVariables
>;
