import React, { useCallback } from "react";
import { Box, IconButton } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useRouteMatch } from "react-router";
import useSelectedYear from "../hooks/useSelectedYear";
import { useHistory } from "react-router-dom";
export default function YearPicker() {
    let year = useSelectedYear();

    let match = useRouteMatch<{ year: string }>();
    let history = useHistory();
    const onChange = useCallback(
        (year: number) => {
            let newUrl = match.path;

            newUrl = newUrl.replaceAll(":year", year.toString());

            Object.keys(match.params).forEach((key) => {
                newUrl = newUrl.replaceAll(":" + key, (match.params as any)[key] ?? "");
            });

            history.replace(newUrl);
        },
        [history, match.params, match.path]
    );

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <div>
                <IconButton onClick={() => onChange(year - 1)}>
                    <ArrowBackIos style={{ cursor: "pointer" }} />
                </IconButton>
            </div>
            <h1>{year}</h1>
            <div>
                <IconButton onClick={() => onChange(year + 1)}>
                    <ArrowForwardIos style={{ cursor: "pointer" }} />
                </IconButton>
            </div>
        </Box>
    );
}
