import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import useUrlHelper from "../../hooks/useUrlHelper";
import { useMeQuery } from "../../operations.generated";
import { Card, Col, Container, Nav, Row } from "react-bootstrap";
import MessageList from "../../components/MessageList";
import { useClientConfiguration } from "../../ClientConfigurationContext";
import { useTranslation } from "react-i18next";
import { useLodgingPages } from "../../navigation";

const useStyles = makeStyles({
    media: {
        height: "240px",
    },
});
export default function OverviewView() {
    const classes = useStyles();

    const { loading, data, error } = useMeQuery();

    const messageListRef = useRef<HTMLDivElement>();

    if (error) {
        if (error.graphQLErrors[0].extensions.data["DisplayToClient"]) {
            return <div style={{ marginTop: "20px", marginLeft: "20px"}}>{error.graphQLErrors[0].message}</div>;
        }
        return <div style={{ marginTop: "20px", marginLeft: "20px"}}>Error</div>;
    }

    const history = useHistory();
    const { t } = useTranslation();
    const urlHelper = useUrlHelper();
    const clientConfiguration = useClientConfiguration();
    const lodgingPages = useLodgingPages();

    useEffect(() => {
        if (messageListRef.current) {
            messageListRef.current.scrollTo({
                top: messageListRef.current.scrollHeight,
            });
        }
    }, [data]);

    if (loading) {
        return <PageLoader />;
    }

    return (
        <Container className="mt-3 mb-3">
            {data?.me?.name && <h2>{t("frontPageGreeting", { name: data?.me?.name })}</h2>}
            <Row xs={1} md={2} className="g-4">
                {data &&
                    data.me!.lodgings.map((l) => (
                        <Col key={l.id}>
                            <Card className="shadow-sm">
                                <Card.Img
                                    variant="top"
                                    className={classes.media}
                                    style={{ objectFit: "cover" }}
                                    src={l.primaryImage?.locationTemplate.replace(
                                        "{size}",
                                        "auto-480"
                                    )}
                                />
                                <Card.Body>
                                    <h5>{l.name}</h5>
                                    <div color="textSecondary">{l.address}</div>
                                </Card.Body>
                                <Card.Footer>
                                    <Nav>
                                        {lodgingPages.map((lodgingPage) => (
                                            <Nav.Item>
                                                <Nav.Link
                                                    style={{ color: "black" }}
                                                    onClick={() => lodgingPage.goToPage(l.id)}
                                                >
                                                    {lodgingPage.icon} {lodgingPage.name}
                                                </Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                {clientConfiguration.showMessages && (
                    <Col>
                        <Card className="shadow-sm">
                            <Card.Body>
                                <Card.Title>{t("messages")}</Card.Title>

                                <div style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            position: "absolute",
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            height: "50px",
                                            background: "linear-gradient(0deg,transparent,white)",
                                        }}
                                    />
                                    <div
                                        ref={messageListRef}
                                        style={{
                                            height: "260px",
                                            overflow: "hidden",
                                            position: "relative",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "end",
                                        }}
                                    >
                                        <MessageList messages={data.me.messages} />
                                    </div>
                                </div>
                            </Card.Body>
                            <Card.Footer>
                                <Nav>
                                    <Nav.Item>
                                        <Nav.Link
                                            onClick={() => history.push(urlHelper.messages)}
                                            style={{ color: "black" }}
                                        >
                                            {t("goToMessages")}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Footer>
                        </Card>
                    </Col>
                )}
            </Row>
        </Container>
    );
}
