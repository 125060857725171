import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonVariant } from "react-bootstrap/types";

export default function ConfirmModal({
    confirmState,
    onClose,
}: {
    confirmState: ConfirmState;
    onClose: () => void;
}) {
    const { t } = useTranslation();
    const stickyState = useRef<ConfirmState>();
    const [isExecuting, setIsExecuting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        if (confirmState != null) {
            stickyState.current = confirmState;
        }
    }, [confirmState]);

    async function execute() {
        setIsExecuting(true);
        try {
            await confirmState.then();
            setIsExecuting(false);
            onClose();
        } catch (ex) {
            setError(true);
            setIsExecuting(false);
        }
    }

    const actionState = confirmState || stickyState.current;

    return (
        <Modal show={confirmState != null} onHide={() => onClose()} style={{ zIndex: 10000 }}>
            {actionState != null && (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>{t("confirmAction")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {actionState.message}
                        {error && (
                            <div className="mt-3 text-danger">
                                {t("errorWhileContactingServer")}
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => onClose()}>
                            {t("close")}
                        </Button>
                        <Button
                            variant={actionState.confirmVariant}
                            disabled={isExecuting}
                            onClick={() => execute()}
                        >
                            {actionState.confirmAction}
                        </Button>
                    </Modal.Footer>
                </>
            )}
        </Modal>
    );
}

export interface ConfirmState {
    message: React.ReactNode;
    then: () => Promise<any>;
    confirmVariant: ButtonVariant;
    confirmAction: React.ReactNode;
}
