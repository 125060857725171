import React, { useEffect } from "react";
import { useClientInformationQuery } from "./operations.generated";
import i18n from "i18next";
import App from "./App";

export default function AppWrapper() {
    const query = useClientInformationQuery();

    useEffect(() => {
        if (query.data) {
            i18n.changeLanguage(query.data.clientConfiguration.defaultCulture.split("-")[0]);
        }
    }, [query.data]);

    if (query.loading) {
        return <div />;
    }

    if (query.error) {
        return <div>Error fetching client information</div>;
    }

    if (query.data?.clientConfiguration) {
        return <App clientConfiguration={query.data?.clientConfiguration} />;
    } else {
        return <div>Error fetching client information. ClientInformation was null</div>;
    }
}
