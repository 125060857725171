import { useClientConfiguration } from "./ClientConfigurationContext";
import useUrlHelper from "./hooks/useUrlHelper";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { faCalendarAlt, faChartBar, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useRouteMatch } from "react-router";
import useSelectedYear from "./hooks/useSelectedYear";
import useSelectedLodgingId from "./hooks/useSelectedLodgingId";

export function useLodgingPages(): LodgingPageInformation[] {
    const clientConfiguration = useClientConfiguration();
    const urlHelper = useUrlHelper();
    const year = useSelectedYear();
    const lodgingId = useSelectedLodgingId();
    const history = useHistory();
    const { t } = useTranslation();
    const match = useRouteMatch();

    let pages = [] as LodgingPageInformation[];

    console.log("match", match);

    pages.push({
        name: t("report"),
        icon: <FontAwesomeIcon icon={faChartBar} />,
        goToPage: (lodgingId) => history.push(urlHelper.lodgingReport(lodgingId)),
        isActive: match.url == urlHelper.lodgingReport(lodgingId, year),
    });

    pages.push({
        name: t("calendar"),
        icon: <FontAwesomeIcon icon={faCalendarAlt} />,
        goToPage: (lodgingId) => history.push(urlHelper.lodgingCalendar(lodgingId)),
        isActive: match.url == urlHelper.lodgingCalendar(lodgingId, year),
    });

    if (clientConfiguration.showRentalContracts) {
        pages.push({
            name: t("rentalContract"),
            icon: <FontAwesomeIcon icon={faFileInvoice} />,
            goToPage: (lodgingId) => history.push(urlHelper.lodgingRentalContract(lodgingId)),
            isActive: match.url == urlHelper.lodgingRentalContract(lodgingId, year),
        });
    }

    return pages;
}

interface LodgingPageInformation {
    name: string;
    icon: React.ReactNode;
    goToPage: (lodgingId: number) => void;
    isActive: boolean;
}

/*

<Nav>
    <Nav.Item>
        <Nav.Link
            onClick={() =>
                history.push(urlHelper.lodgingReport(l.id))
            }
        >
            {t("report")}
        </Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link
            onClick={() =>
                history.push(urlHelper.lodgingCalendar(l.id))
            }
        >
            {t("calendar")}
        </Nav.Link>
    </Nav.Item>
    {clientConfiguration.showRentalContracts && (
        <Nav.Item>
            <Nav.Link
                onClick={() =>
                    urlHelper.lodgingRentalContract(l.id)
                }
            >
                {t("rentalContract")}
            </Nav.Link>
        </Nav.Item>
    )}

 */
