import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    Container,
    TextField,
    Checkbox,
    FormControlLabel
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useLoginLazyQuery } from "../../operations.generated";
import { useClientConfiguration } from "../../ClientConfigurationContext";
import { clearApolloCache } from "../../index";
import { useTranslation } from "react-i18next";
export default function LoginView() {
    const configuration = useClientConfiguration();
    const [loginQuery, loginQueryResult] = useLoginLazyQuery();
    const { t } = useTranslation();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [persistent, setPersistent] = useState(false);
    const [loginDone, setLoginDone] = useState(false);
    let ll = false;
    const handleSubmit = useCallback(
        (e: React.SyntheticEvent) => {
            loginQuery({
                variables: {
                    username,
                    password,
                    persistent
                },
            }).then(r => {
                if (r.data.login) {
                    setLoginDone(true)
                }
            });
            e.preventDefault();
        },
        [loginQuery, password, username]
    );
    const loginInProgress = loginQueryResult.loading || ll;
    const loginFailed =
        loginQueryResult.called &&
        !loginQueryResult.loading &&
        loginQueryResult.data?.login == null;
    const token = loginQueryResult.data?.login;

    useEffect(() => {
        if (token != null) {
            clearApolloCache().then(() => {
                setLoginDone(true);
            });
        }
    }, [token]);

    return (
        <Container maxWidth="xs">
            {loginDone && <Redirect to="/overview" />}
            <Box textAlign="center">
                <h1>{configuration.appTitle}</h1>
                <h2>{t("login")}</h2>
            </Box>
            <Box pt={2}>
                <form onSubmit={handleSubmit}>
                    <Card>
                        <CardContent>
                            <Box mb={2}>
                                <TextField
                                    label={t("username")}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={username}
                                    onChange={(e) => setUsername(e.currentTarget.value)}
                                />
                            </Box>
                            <Box mb={2}>
                                <TextField
                                    type="password"
                                    label={t("password")}
                                    fullWidth
                                    required
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.currentTarget.value)}
                                />
                            </Box>
                            <Box mb={2}>
                                <FormControlLabel control={<Checkbox onChange={(e) => setPersistent(e.currentTarget.checked)} />} label={t("rememberMeFor30Days")} />
                            </Box>
                            <Collapse in={loginFailed}>
                                <Box mb={2} color="error.main">
                                    {t("loginFailed")}
                                </Box>
                            </Collapse>
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {!loginInProgress && (
                                    <Button
                                        color="primary"
                                        type="submit"
                                        variant="contained"
                                        fullWidth
                                        size="large"
                                    >
                                        {t("login")}
                                    </Button>
                                )}
                                {loginInProgress && <CircularProgress />}
                            </Box>
                        </CardContent>
                    </Card>
                </form>
            </Box>
        </Container>
    );
}
