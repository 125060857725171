import { createContext, useContext } from "react";
import { CreditorConfigurationQuery } from "./operations.generated";

export type CreditorConfiguration = CreditorConfigurationQuery["clientConfigurationCreditorSpecific"];

const context = createContext<CreditorConfiguration>(null as any);

export const CreditorConfigurationContext = context.Provider;

export function useCreditorConfiguration() {
    return useContext<CreditorConfiguration>(context);
}
