import React from "react";
import {
    Box,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Paper,
} from "@material-ui/core";
import { CreditorRentalSummaryReportQuery } from "operations.generated";
import FancyPeriod from "components/FancyPeriod";
import { useTranslation } from "react-i18next";
import { useClientConfiguration } from "../../../../ClientConfigurationContext";

export default function BlockingList({ data }: { data: CreditorRentalSummaryReportQuery }) {
    const { t } = useTranslation();
    const clientConfiguration = useClientConfiguration();

    return (
        <Paper>
            <List subheader={<ListSubheader>{t("blockings")}</ListSubheader>}>
                {data.creditorRentalSummaryReport.blockings.map((b, index) => (
                    <React.Fragment key={index}>
                        {index !== 0 && <Divider />}
                        <ListItem style={{ minHeight: "72px" }}>
                            <ListItemText>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <FancyPeriod
                                        arrivalDate={b.blockedFrom}
                                        departureDate={b.blockedTo}
                                    />
                                    <span>{clientConfiguration.blockingGroupTranslations.find(x => x.key == b.blockingGroupId.toString())?.value ?? b.blockingGroup}</span>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    </React.Fragment>
                ))}
                {data.creditorRentalSummaryReport.blockings.length === 0 && (
                    <ListItem>
                        <ListItemText>{t("noBlockings")}</ListItemText>
                    </ListItem>
                )}
            </List>
        </Paper>
    );
}
