import React from "react";
import { useState } from "react";
import { Box, CircularProgress, Container, Grid, Button, Dialog } from "@material-ui/core";
import { isAfter, isEqual, parseISO, startOfToday } from "date-fns";
import MonthCalendar, { BlockingDateItem, DateItem } from "components/MonthCalendar";
import YearPicker from "components/YearPicker";
import useSelectedLodgingId from "hooks/useSelectedLodgingId";
import { useCreditorRentalSummaryReportQuery, useOverlapsQuery } from "operations.generated";
import useSelectedYear from "hooks/useSelectedYear";
import BottomPanel from "components/BottomPanel";
import { useDetailReservationDrawer } from "views/drawers/DetailReservationView";
import BlockingLegendDialog from "../../../components/BlockingLegendCallout";

import AddIcon from "@material-ui/icons/Add";
import { useMutateBlockingDialog } from "views/dialogs/MutateBlockingDialog";
import FloatingFob from "../../../components/FloatingFob";
import { useClientConfiguration } from "../../../ClientConfigurationContext";
import { ReservationStatus } from "../../../graphql-types.generated";
import { useCreditorConfiguration } from "../../../CreditorConfigurationContext";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";
export default function CalendarView() {
    const year = useSelectedYear();
    const lodgingId = useSelectedLodgingId();
    const { data, loading, error } = useCreditorRentalSummaryReportQuery({
        variables: {
            year,
            lodgingId,
        },
    });
    const overlapResult = useOverlapsQuery({
        variables:{
            intersectDate: `${year-1}-12-31`,
            lodgingId: lodgingId
        }
    })

    const detailReservationDrawer = useDetailReservationDrawer();
    const mutateBlockingDialog = useMutateBlockingDialog();
    const creditorConfiguration = useCreditorConfiguration();
    const clientConfiguration = useClientConfiguration();
    const { t } = useTranslation();
    const [showLegend, setShowLegend] = useState(false);
    const [showOtherYearAlert, setShowOtherYearAlert] = useState(false);
    const [otherYear, setOtherYear] = useState<number>(null)

    if (error) {
        if (error.graphQLErrors[0].extensions.data["DisplayToClient"]) {
            return <div style={{ marginTop: "20px", marginLeft: "20px"}}>{error.graphQLErrors[0].message}</div>;    
        }
        return <div style={{ marginTop: "20px", marginLeft: "20px"}}>Error</div>;
    }
    
    if (loading ||overlapResult.loading || data == null) {
        return (
            <Container>
                <YearPicker />
                <Box
                    style={{
                        display: "flex",
                        marginTop: "150px",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
                <BottomPanel />
            </Container>
        );
    }

    const dateItems: DateItem[] = [];
    const blockingGroupIds: number[] = [];
    data.creditorRentalSummaryReport.reservations
        .filter(
            (r) =>
                r.reservationStatus === ReservationStatus.Invoice ||
                r.reservationStatus === ReservationStatus.Quote ||
                r.reservationStatus === ReservationStatus.Option
        )
        .forEach((r) => {
            dateItems.push({
                from: parseISO(r.arrivalDate),
                to: parseISO(r.departureDate),
                type: r.reservationStatus == "OPTION" ? "option" : "reservation",
                reservation: r,
            });
        });
    
    if(overlapResult.data.intersectingBookings.length > 0){
        overlapResult.data.intersectingBookings.forEach(r => {
        dateItems.push({
            from: parseISO(r.arrivalDate),
            to: parseISO(r.departureDate),
            type: "shadow",
            shadowReservationId: r.reservationId
        });
        });
    }

    data.creditorRentalSummaryReport.blockings.forEach((b) => {
        dateItems.push({
            from: parseISO(b.blockedFrom),
            to: parseISO(b.blockedTo),
            type: "blocking",
            blocking: b,
            color: clientConfiguration.blockingGroupColors?.find(
                (x) => x?.key == b.blockingGroupId.toString()
            )?.value,
        });
        if (
            typeof b.blockingGroupId === "number" &&
            blockingGroupIds.findIndex((x) => x == b.blockingGroupId) == -1
        ) {
            blockingGroupIds.push(b.blockingGroupId);
        }
    });

    function canEditBlocking(dateItem: BlockingDateItem) {
        return (
            dateItem.blocking.blockingGroupId === clientConfiguration.blockingTypeId &&
            isAfter(dateItem.from, startOfToday())
        );
    }

    function handleDateItemClicked(dateItem: DateItem) {
        if(dateItem.type == "shadow"){
                setOtherYear(dateItem.from.getFullYear());
                setShowOtherYearAlert(true);
            return;
        }
        switch (dateItem.type) {
            case "reservation":
                detailReservationDrawer.setDetailReservationAndOpen(dateItem.reservation);
                break;
            case "option":
                detailReservationDrawer.setDetailReservationAndOpen(dateItem.reservation);
                break;
            case "blocking":

                if (canEditBlocking(dateItem)) {
                    mutateBlockingDialog.showEdit({
                        id: dateItem.blocking.id,
                        from: dateItem.from,
                        to: dateItem.to,
                        comment: dateItem.blocking.publicNote,
                    }, creditorConfiguration.allowCreateBlocking, creditorConfiguration.allowDeleteBlocking);
                    break;
                } else {
                    if (isEqual(startOfToday(), dateItem.from) || isAfter(startOfToday(), dateItem.from)) {
                        alert(t("canNotEditBlockingInThePast"));
                    }
                }
        }
    }

    return (
        <Container>
            {detailReservationDrawer.drawer}
            {mutateBlockingDialog.dialog}
            <Box>
                <YearPicker />
            </Box>
            <Box alignItems="center" display="flex" justifyContent="center" pb={2}>
                <Button
                    startIcon={<InfoIcon />}
                    color="primary"
                    size="small"
                    onClick={() => {
                        setShowLegend(true);
                    }}
                >
                    {t("legend")}
                </Button>
            </Box>
            <Grid container spacing={2}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((m) => (
                    <Grid key={m} item xs={12} sm={6} md={4} lg={3}>
                        <MonthCalendar
                            year={year}
                            month={m}
                            dateItems={dateItems}
                            onDateItemClicked={handleDateItemClicked}
                        />
                    </Grid>
                ))}
            </Grid>
            {creditorConfiguration.allowCreateBlocking && (
                <>
                    <Box mt={2}>
                        <Alert severity="info">
                            <span>{t("createBlockingHelp")}</span>
                            <span>
                                {creditorConfiguration.allowDeleteBlocking && t("editBlockingHelp")}
                            </span>
                        </Alert>
                    </Box>
                    <FloatingFob
                        icon={<AddIcon />}
                        onClick={() => mutateBlockingDialog.showCreateNew(creditorConfiguration.allowCreateBlocking, creditorConfiguration.allowDeleteBlocking)}
                    />
                </>
            )}
            <BlockingLegendDialog
                clientConfig={clientConfiguration}
                open={showLegend}
                onClose={() => {
                    setShowLegend(false);
                }}
                blockingGroupIds={blockingGroupIds}
            />
            <BottomPanel />
            <Dialog open={showOtherYearAlert} fullWidth={true} onClose={()=>{setShowOtherYearAlert(false); setOtherYear(null)}}>
                <Box p={2}>
                {t("reservationFromDifferentYear", {year: otherYear})}
                </Box>
            </Dialog>
        </Container>
    );
}   
