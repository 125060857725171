import React from "react";
import { Container, Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Props {
    icon: React.ReactElement;
    onClick: () => void;
    alignTop?:boolean;
}

const useStyles = makeStyles((theme) => ({
    fab: {
        position: "fixed",
        bottom: theme.spacing(2) + 56,
        zIndex: 100,
    },
    fabTop: {
        position: "fixed",
        top: theme.spacing(2) + 56,
        zIndex: 100,
    },
}));

export default function FloatingFob({ icon, onClick, alignTop }: Props) {
    const classNames = useStyles();
    return (
        <Container>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {<Fab className={alignTop ? classNames.fabTop : classNames.fab} color="primary" aria-label="add" onClick={onClick}>
                    {icon}
                </Fab>}
            </div>
        </Container>
    );
}
