import {
    AppBar,
    Box,
    Button,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Toolbar,
    useTheme,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import useUrlHelper from "../hooks/useUrlHelper";
import { useMeQuery } from "../operations.generated";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useClientConfiguration } from "../ClientConfigurationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faHome } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useLodgingPages } from "../navigation";

export default function TopNavigation() {
    const theme = useTheme();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const urlHelper = useUrlHelper();
    const clientConfiguration = useClientConfiguration();
    const { data, loading } = useMeQuery();
    const lodgingPages = useLodgingPages();

    const handleListItemClick = useCallback(
        (url: string) => {
            return () => {
                history.push(url);
                setIsDrawerOpen(false);
            };
        },
        [history]
    );

    if (loading || data == null) {
        return <div />;
    }

    return (
        <>
            <AppBar
                position="fixed"
                style={{
                    backgroundColor: (theme as any).appBar.backgroundColor,
                    color: (theme as any).appBar.color,
                }}
            >
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={() => setIsDrawerOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Button onClick={() => history.push(urlHelper.overview)}>
                        {(clientConfiguration.logoUrl && (
                            <img
                                alt="Logo"
                                style={{ height: "32px" }}
                                src={clientConfiguration.logoUrl}
                            />
                        )) || (
                            <span style={{ color: (theme as any).appBar.color }}>
                                {clientConfiguration.appTitle}
                            </span>
                        )}
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen((value) => !value)}
            >
                <Box style={{ width: "250px" }}>
                    <List>
                        <ListItem button onClick={handleListItemClick(urlHelper.overview)}>
                            <ListItemIcon>
                                <FontAwesomeIcon icon={faHome} />
                            </ListItemIcon>
                            <ListItemText primary={t("overview")} />
                        </ListItem>
                        {clientConfiguration.showMessages && (
                            <ListItem button onClick={handleListItemClick(urlHelper.messages)}>
                                <ListItemIcon>
                                    <FontAwesomeIcon icon={faCommentAlt} />
                                </ListItemIcon>
                                <ListItemText primary={t("messages")} />
                            </ListItem>
                        )}
                    </List>
                    {data.me!.lodgings.map((l) => (
                        <React.Fragment key={l.id}>
                            <List
                                subheader={
                                    <ListSubheader>
                                        {t("lodging")} {l.name}
                                    </ListSubheader>
                                }
                            >
                                {lodgingPages.map((page, index) => (
                                    <ListItem
                                        key={index}
                                        button
                                        onClick={() => {
                                            page.goToPage(l.id);
                                            setIsDrawerOpen(false);
                                        }}
                                    >
                                        <ListItemIcon>{page.icon}</ListItemIcon>
                                        <ListItemText primary={page.name} />
                                    </ListItem>
                                ))}
                            </List>
                        </React.Fragment>
                    ))}
                    <Divider />
                    <List>
                        <ListItem
                            button
                            onClick={() => {
                                history.push(urlHelper.logout);
                            }}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("logout")} />
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
        </>
    );
}
