import React from "react";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import { CreditorRentalSummaryReportQuery } from "../operations.generated";
import { useTranslation } from "react-i18next";

type Reservation =
    CreditorRentalSummaryReportQuery["creditorRentalSummaryReport"]["reservations"][0];

export default function BookingTimeline({ data }: { data: Reservation }) {
    const { t } = useTranslation();

    return (
        <Timeline>
            {data.orderItems.map((orderItem, index) => (
                <TimelineItem>
                    <TimelineOppositeContent style={{ flex: 0, whiteSpace: "nowrap" }}>
                        <Typography color="textSecondary" variant="body2">
                            {orderItem.orderDateDisplayValue}
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        {index !== data.orderItems.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                        <Typography color={orderItem.isCancelled ? "error" : undefined}>
                            {orderItem.isBookingInvoice
                                ? data.reservationStatus == "OPTION"
                                    ? t("created")
                                    : t("bookingCreated")
                                : t(`orderItemType.${orderItem.orderItemType}`)}
                        </Typography>
                        {data.reservationStatus != "OPTION" && (
                            <div>
                                {orderItem.lines.map((line, lineIndex) => (
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                        key={lineIndex}
                                    >
                                        {orderItem.isBookingInvoice && lineIndex === 0
                                            ? t("rental")
                                            : <span dangerouslySetInnerHTML={{__html:line.invoiceLineDescription}} ></span>}
                                        : {line.principalSumDisplayValue}
                                    </Typography>
                                ))}
                            </div>
                        )}
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}
