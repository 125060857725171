import React from "react";
import FancyDate from "./FancyDate";
import { ArrowRight } from "@material-ui/icons";
import { Box } from "@material-ui/core";

export default function FancyPeriod({
    arrivalDate,
    departureDate,
}: {
    arrivalDate: string;
    departureDate: string;
}) {
    return (
        <Box display="flex" alignItems="center">
            <FancyDate date={arrivalDate} />
            <Box mx={1}>
                <ArrowRight color="disabled" />
            </Box>
            <FancyDate date={departureDate} />
        </Box>
    );
}
