import React from "react";
import { Dialog, Box } from "@material-ui/core";
import { ClientConfiguration } from "../ClientConfigurationContext";
import { useTranslation } from "react-i18next";

export default function BlockingLegendDialog({
    clientConfig,
    open,
    onClose,
    blockingGroupIds,
}: {
    clientConfig: ClientConfiguration;
    open: boolean;
    onClose: () => void;
    blockingGroupIds: number[];
}) {
    const { t } = useTranslation();
    let existingLegends: { color: string; text: string }[] = [];
    return (
        <Dialog open={open} fullWidth={true} onClose={onClose}>
            <Box p={2}>
                <h3 style={{ textAlign: "center" }}>{t("legend")}</h3>
                <Box>
                    <div
                        style={{
                            display: "inline-block",
                            width: "32px",
                            height: "32px",
                            backgroundColor: "blue",
                            marginRight: "15px",
                        }}
                    />
                    <label style={{ lineHeight: "32px", verticalAlign: "top" }}>
                        {t("reservation")}
                    </label>
                </Box>
                <Box>
                    <div
                        style={{
                            display: "inline-block",
                            width: "32px",
                            height: "32px",
                            backgroundColor: "lightblue",
                            marginRight: "15px",
                        }}
                    />
                    <label style={{ lineHeight: "32px", verticalAlign: "top" }}>
                        {t("option")}
                    </label>
                </Box>
                {clientConfig.blockingGroupTranslations?.map((trans, index) => {
                    let color = "red";
                    let blockingColor =
                        clientConfig.blockingGroupColors?.find(
                            (x) => x != null && x.key == trans?.key
                        )?.value ?? null;
                    if (blockingColor != null && blockingColor != "") {
                        color = blockingColor;
                    }

                    if (
                        existingLegends.findIndex(
                            (x) => color == x.color && x.text == (trans?.value ?? "")
                        ) != -1 ||
                        blockingGroupIds.findIndex((b) => b.toString(10) == trans?.key) == -1
                    ) {
                        return <></>;
                    }

                    existingLegends.push({ color: color, text: trans?.value ?? "" });

                    return (
                        <Box key={index}>
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "32px",
                                    height: "32px",
                                    backgroundColor: color,
                                    marginRight: "15px",
                                }}
                            />
                            <label style={{ lineHeight: "32px", verticalAlign: "top" }}>
                                {trans?.value ?? t("blocking")}
                            </label>
                        </Box>
                    );
                }) ?? [
                    <Box key={0}>
                        <div
                            style={{
                                display: "inline-block",
                                width: "32px",
                                height: "32px",
                                backgroundColor: "red",
                                marginRight: "15px",
                            }}
                        />
                        <label style={{ lineHeight: "32px", verticalAlign: "top" }}>
                            {t("blocking")}
                        </label>
                    </Box>,
                ]}
            </Box>
        </Dialog>
    );
}
